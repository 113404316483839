export const scope1Data = [
    {
        year: 2019,
        data: [
            {
                emission_type: 'Mobile Combustion',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                emission_type: 'Stationary Combustion',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                emission_type: 'Fugitive Emissions',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
        ]
    },
    {
        year: 2020,
        data: [
            {
                emission_type: 'Mobile Combustion',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                emission_type: 'Stationary Combustion',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                emission_type: 'Fugitive Emissions',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
        ]
    },
    {
        year: 2021,
        data: [
            {
                emission_type: 'Mobile Combustion',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                emission_type: 'Stationary Combustion',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                emission_type: 'Fugitive Emissions',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
        ]
    }
]

export const companyOwnedControlledFacilities = [
    {
        year: 2019,
        data: [
            {
                type: 'Burning Oil',
                unit: 'kwh',
                total: '0',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                type: 'Diesel',
                unit: 'kwh',
                total: '0',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                type: 'Fuel Oil',
                unit: 'kwh',
                total: '0',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                type: 'Gas Oil',
                unit: 'kwh',
                total: '0',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                type: 'LPG',
                unit: 'kwh',
                total: '0',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
        ]
    },
    {
        year: 2020,
        data: [
            {
                type: 'Burning Oil',
                unit: 'kwh',
                total: '0',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                type: 'Diesel',
                unit: 'kwh',
                total: '0',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                type: 'Fuel Oil',
                unit: 'kwh',
                total: '0',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                type: 'Gas Oil',
                unit: 'kwh',
                total: '0',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
            {
                type: 'LPG',
                unit: 'kwh',
                total: '0',
                tco2e: '0',
                percent_total_year_co2: '0',
                per_FTE: '0'
            },
        ]
    }
]

export const EmissionsByKgco2eData = [
            {
                year: '2019',
                energy: '0',
                products_and_services: '0',
                transport: '0',
                waste: '0',
            },
            {
                year: '2020',
                energy: '0',
                products_and_services: '0',
                transport: '0',
                waste: '0',
            },
            {
                year: '2021',
                energy: '0',
                products_and_services: '0',
                transport: '0',
                waste: '0',
            },
            {
                year: '2022',
                energy: '0',
                products_and_services: '0',
                transport: '0',
                waste: '0',
            },
        ]