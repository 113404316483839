import React, { useState, useEffect } from "react";
import { Grid, Cell } from "react-foundation";
import { useParams } from "react-router-dom";
import axiosApi from "../api/axiosInstance";

function AddCompanyTargets() {
  const { company } = useParams();
  const [formRepeaterItems, setFormRepeaterItems] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleStartDateDayChange = (e, index) => {
    const newItems = [...formRepeaterItems];
    newItems[index].startDate.day = e.target.value;
    setFormRepeaterItems(newItems);
  };

  const handleStartDateMonthChange = (e, index) => {
    const newItems = [...formRepeaterItems];
    newItems[index].startDate.month = e.target.value;
    setFormRepeaterItems(newItems);
  };

  const handleStartDateYearChange = (e, index) => {
    const newItems = [...formRepeaterItems];
    newItems[index].startDate.year = e.target.value;
    setFormRepeaterItems(newItems);
  };

  const handleEndDateDayChange = (e, index) => {
    const newItems = [...formRepeaterItems];
    newItems[index].endDate.day = e.target.value;
    setFormRepeaterItems(newItems);
  };

  const handleEndDateMonthChange = (e, index) => {
    const newItems = [...formRepeaterItems];
    newItems[index].endDate.month = e.target.value;
    setFormRepeaterItems(newItems);
  };

  const handleEndDateYearChange = (e, index) => {
    const newItems = [...formRepeaterItems];
    newItems[index].endDate.year = e.target.value;
    setFormRepeaterItems(newItems);
  };

  const handleTargetMeasurementTypeChange = (e, index) => {
    const newItems = [...formRepeaterItems];
    newItems[index].targetMeasurementType = e.target.value;
    setFormRepeaterItems(newItems);
  };

  const handleEnergyTypeChange = (e, index) => {
    const newItems = [...formRepeaterItems];
    newItems[index].energyType = e.target.value;
    setFormRepeaterItems(newItems);
  };

  const handlePercentageTargetChange = (e, index) => {
    const newItems = [...formRepeaterItems];
    newItems[index].percentageTarget = parseInt(e.target.value);
    setFormRepeaterItems(newItems);
  };

  const handleTargetGoalChange = (e, index) => {
    const newItems = [...formRepeaterItems];
    newItems[index].targetGoal = e.target.value;
    setFormRepeaterItems(newItems);
  };
  
  const loadCompanyTargets = async () => {
    try {
      const response = await axiosApi.get('/company-targets');
      console.log('Response from API:', response.data); // Log the entire response data
      // Check if the response data contains the expected property holding the array of targets
      const targetsList = response.data.data.list || [];
      if (Array.isArray(targetsList) && targetsList.length > 0) {
        const formattedTargets = targetsList.map(target => ({
          id: target.id,
          startDate: {
            day: target.start_date.split('-')[2],
            month: target.start_date.split('-')[1],
            year: target.start_date.split('-')[0],
          },
          endDate: {
            day: target.end_date.split('-')[2],
            month: target.end_date.split('-')[1],
            year: target.end_date.split('-')[0],
          },
          targetMeasurementType: target.target_measurement_type,
          energyType: parseInt(target.energy_type),
          percentageTarget: parseFloat(target.percentage_target),
          targetGoal: target.target_goal,
        }));
        setFormRepeaterItems(formattedTargets);
      } else {
        console.error('No company targets found in the response.');
      }
    } catch (error) {
      console.error('Error loading company targets:', error);
      // Handle error if needed
    }
  };

  useEffect(() => {
    loadCompanyTargets();
  }, []);

  const handleAddFormRepeaterItem = () => {
    const newFormRepeaterItem = {
      startDate: { day: "", month: "", year: "" },
      endDate: { day: "", month: "", year: "" },
      targetMeasurementType: "",
      energyType: null,
      percentageTarget: 0,
      targetGoal: ""
    };
    setFormRepeaterItems([...formRepeaterItems, newFormRepeaterItem]);
  };

  const handleSave = (event) => {
    event.preventDefault();
    
    // Prepare requests for both adding new records and updating existing records
    const requests = formRepeaterItems.map(item => {
      const requestData = {
        company_id: 1,
        start_date: `${item.startDate.year}-${item.startDate.month}-${item.startDate.day}`,
        end_date: `${item.endDate.year}-${item.endDate.month}-${item.endDate.day}`,
        target_measurement_type: item.targetMeasurementType,
        energy_type: !isNaN(item.energyType) ? item.energyType : null,
        percentage_target: item.percentageTarget,
        target_goal: item.targetGoal
      };
      console.log(item.targetMeasurementType);
      console.log(item.energyType);

      // If the item has an ID, it means it's an existing record, so we make an update request
      if (item.id) {
        return axiosApi.put(`/company-targets/${item.id}`, requestData);
      } else {
        // Otherwise, it's a new record, so we make a post request
        return axiosApi.post('/company-targets', requestData);
      }
    });

    // Execute all requests concurrently using Promise.all
    Promise.all(requests)
      .then((responses) => {
        // Set success message
        setSuccessMessage("Company targets saved successfully!");
        // Clear any previous error message
        setErrorMessage("");
        // Update the formRepeaterItems state with any newly added records
        const updatedItems = responses.map(response => response.data);
        setFormRepeaterItems(updatedItems);
        // Reload company targets after successful save
        loadCompanyTargets();
      })
      .catch((error) => {
        console.error(error);
        // Set error message
        setErrorMessage("Failed to save company targets. Please try again.");
      });
  };

  return (
    <Grid>
      <Cell small={12} medium={12} large={12}>
        {/* Selection Bar */}
        {/* Dashboard */}
        <div className="dashContainer">
          <div className="dashContainerHeader">
            <strong>Add Company Target</strong>
          </div>
          <Grid className="display">
            <Cell small={12} medium={12} large={12}>
              <div className="quickSelectTab">
                <h4>Company Targets</h4>
                {/* Form Repeater */}
                <form onSubmit={handleSave}>
                {formRepeaterItems.map((item, index) => (
                  <div key={item.id}>
                    <div>
                      <p>Start Date</p>
                    </div>
                    <div>
                      {/* Start Date Input Fields */}
                      {/* Day */}
                      <select
                        name={`start_date_day_${index}`}
                        id={`start_date_day_${index}`}
                        style={{ width: "33%" }}
                        value={item.startDate?.day}
                        onChange={(e) => handleStartDateDayChange(e, index)}
                      >
                        <option value="">DD</option>
                        {Array.from({ length: 31 }, (_, index) => index + 1).map(
                          (startDay) => (
                            <option key={startDay} value={startDay < 10 ? `0${startDay}` : startDay}>
                            {startDay < 10 ? `0${startDay}` : startDay}
                            </option>
                          )
                        )}
                      </select>
                      {/* Month */}
                      <select
                        name={`start_date_month_${index}`}
                        id={`start_date_month_${index}`}
                        style={{ width: "33%" }}
                        className="c-select c-select--custom c-select__date u-width-full"
                        value={item.startDate?.month}
                        onChange={(e) => handleStartDateMonthChange(e, index)}
                      >
                          <option value="">MM</option>
                          {Array.from({ length: 12 }, (_, index) => index + 1).map((month) => {
                            const monthObj = new Date(0, month - 1);
                            const monthName = monthObj.toLocaleString("default", { month: "short" });
                            const monthValue = month < 10 ? `0${month}` : month;
                            return (
                              <option key={month} value={monthValue}>
                                {monthName}
                              </option>
                            );
                          })}
                      </select>
                      {/* Year */}
                      <select
                        name={`start_date_year_${index}`}
                        id={`start_date_year_${index}`}
                        style={{ width: "33%" }}
                        className="c-select c-select--custom c-select__date u-width-full"
                        value={item.startDate?.year}
                        onChange={(e) => handleStartDateYearChange(e, index)}
                      >
                        <option value="">YYYY</option>
                        {Array.from({ length: 30 }, (_, index) => new Date().getFullYear() - 20 + index).map(
                          (year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div>
                      <p>End Date</p>
                    </div>
                    <div>
                      {/* End Date Input Fields */}
                      {/* Day */}
                      <select
                        name={`end_date_day_${index}`}
                        id={`end_date_day_${index}`}
                        style={{ width: "33%" }}
                        value={item.endDate?.day}
                        onChange={(e) => handleEndDateDayChange(e, index)}
                      >
                        <option value="">DD</option>
                        {Array.from({ length: 31 }, (_, index) => index + 1).map((startDay) => (
                          <option key={startDay} value={startDay < 10 ? `0${startDay}` : startDay}>
                            {startDay < 10 ? `0${startDay}` : startDay}
                          </option>
                          )
                        )}
                      </select>
                      {/* Month */}
                      <select
                        name={`end_date_month_${index}`}
                        id={`end_date_month_${index}`}
                        style={{ width: "33%" }}
                        className="c-select c-select--custom c-select__date u-width-full"
                        value={item.endDate?.month}
                        onChange={(e) => handleEndDateMonthChange(e, index)}
                      >
                        <option value="">MM</option>
                        <option value="">MM</option>
                          {Array.from({ length: 12 }, (_, index) => index + 1).map((month) => {
                            const monthObj = new Date(0, month - 1);
                            const monthName = monthObj.toLocaleString("default", { month: "short" });
                            const monthValue = month < 10 ? `0${month}` : month;
                            return (
                              <option key={month} value={monthValue}>
                                {monthName}
                              </option>
                            );
                          })}
                      </select>
                      {/* Year */}
                      <select
                        name={`end_date_year_${index}`}
                        id={`end_date_year_${index}`}
                        style={{ width: "33%" }}
                        className="c-select c-select--custom c-select__date u-width-full"
                        value={item.endDate?.year}
                        onChange={(e) => handleEndDateYearChange(e, index)}
                      >
                        <option value="">YYYY</option>
                        {Array.from({ length: 30 }, (_, index) => new Date().getFullYear() - 20 + index).map(
                          (year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div style={{ width: "33%" }}>
                        <p>Target Measurement Type</p>
                          <select id="target_measurement_type_${index}" name="target_measurement_type_${index}" value={item.targetMeasurementType} onChange={(e) => handleTargetMeasurementTypeChange(e, index)} >
                              <option value=''>Please Select a Measurement Type</option>
                              <option value='Built Environment'>Built Environment</option>
                              <option value='Waste'>Waste</option>
                              <option value='Transport'>Transport</option>
                              <option value='Land Use'>Land Use</option>
                              <optgroup label='Built Environment'>
                                <option value='Total Energy Emissions'>Total Energy Emissions</option>
                                <option value='Energy'>Energy</option>
                                <option value='Total Water Usage'>Total Water Usage</option>
                                <option value='Total Water Supply'>Total Water Supply</option>
                                <option value='Total Water Treatment'>Total Water Treatment</option>
                                <option value='Daily Water Usage'>Daily Water Usage</option>
                              </optgroup>
                              <optgroup label="Waste">
                                <option value='Waste Tonnage'>Waste Tonnage</option>
                                <option value='Process Emissions'>Process Emissions(Recycle)</option>
                                <option value='Process Emissions'>Process Emissions(Incinerate)</option>
                            </optgroup>                              
                            <optgroup label='Transport'>
                              <option value='Total Miles'>Total Miles</option>
                              <option value='Transport Emissions'>Transport Emissions</option>
                              <option value='Daily tCO2e'>Daily tCO2e</option>
                              <option value='kgCO2e by vehicle'>kgCO2e by vehicle</option>
                            </optgroup>
                            <optgroup label='Land Use'>
                              <option value='Net Biodiversity Gain Percentage'>Net Biodiversity Gain Percentage</option>
                            </optgroup>
                          </select>
                    </div>
                    {item.targetMeasurementType === 'Energy' && (
                    <div style={{ width: "33%" }}>
                        <p>Energy Type</p>
                          <select id="energy_type_${index}" name="energy_type_${index}" value={item.energyType} onChange={(e) => handleEnergyTypeChange(e, index)} >
                              <option value=''>Please Select a Energy Type</option>
                              <option value='1'>Electricity</option>
                              <option value='2'>Natural Gas</option>
                              <option value='5'>Diesel</option>
                              <option value='9'>Fuel Oil</option>
                              <option value='10'>Petrol</option>
                              <option value='11'>Biofuel</option>
                              <option value='15'>Burning Oil</option>
                              <option value='16'>Gas Oil</option>
                              <option value='6'>LPG</option>
                          </select>
                    </div>
                    )}
                    <div>
                        <p>Percentage Target (%)</p>
                    </div>
                    <div>
                      <input
                        type="number"
                        id="percentage_target_${index}"
                        name="percentage_target_${index}"
                        placeholder="Percentage Target (%)"
                        maxLength="50"
                        size="30"
                        style={{ width: "33%" }}
                        value={item.percentageTarget}
                        onChange={(e) => handlePercentageTargetChange(e, index)}
                      />
                    </div>
                    <div>
                        <p>Target Goal</p>
                    </div>
                    <div>
                      <select id="target_goal_${index}" name="target_goal_${index}" value={item.targetGoal} onChange={(e) => handleTargetGoalChange(e, index)} >
                        <option value=''>Please Select a Target Goal</option>
                        <option value='Increase'>Increase</option>
                        <option value='Reduction'>Reduction</option>
                      </select>
                    </div>
                  <hr />
                  </div>
                ))}
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  type="button"
                  onClick={handleAddFormRepeaterItem}
                >
                  Add New Target
                </button>
                <hr />
                <button type='submit' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">Save</button>
                {successMessage && <p>{successMessage}</p>}   
                {errorMessage && <p>{errorMessage}</p>}
                </form>           
                </div>
            </Cell>
          </Grid>
        </div>
      </Cell>
    </Grid>
  );
}

export default AddCompanyTargets;
