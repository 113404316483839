import { Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartTitle, ChartLegend } from '@progress/kendo-react-charts';
import { Card, CardHeader, CardTitle, CardBody, CardActions, CardImage, CardSubtitle, Avatar } from '@progress/kendo-react-layout';

const EmissionsByTypeReport = () => {
    const pieData = [{
        name: 'Energy',
        share: 25
      }, {
        name: 'Products and Services',
        share: 18,
        explode: true
      }, {
        name: 'Transport',
        share: 21
      }, {
        name: 'Waste',
        share: 32
      }];
    return (
        <Chart style={{
            height: 350
        }}>
            <ChartTitle text="" />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartSeries>
                <ChartSeriesItem 
                type={'donut'} 
                tooltip={{visible: true}} 
                data={pieData} 
                categoryField="name" 
                field="share"
                />
            </ChartSeries>
        </Chart>
    )
}

export default EmissionsByTypeReport;