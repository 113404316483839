export const pieData = [{
    name: 'Mobile Combustion',
    share: 0.6
  }, {
    name: 'Stationary Combustion',
    share: 0.15,
    explode: true
  }, {
    name: 'Fugitive Emissions',
    share: 0.25
  },];

  export const pieData2 = [{
    name: 'Burning Oil',
    share: 0.24
  }, {
    name: 'Diesel',
    share: 0.26,
    explode: true
  }, {
    name: 'Fuel Oil',
    share: 0.1
  }, {
    name: 'Gas Oil',
    share: 0.4
  }, {
    name: 'LPG',
    share: 0.4
  }];

  export const lineData = [
    {
        name: 'India',
        data: [3.907, 7.943, 7.848]
    },
];