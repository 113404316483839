import {Grid as DataGrid, GridColumn as Column, GridNoRecords, GridToolbar, GridColumn} from "@progress/kendo-react-grid";

const ComparativeTable = ({year, data, cols}) => {
    return (
        <DataGrid data={data}>
            <GridToolbar>
                <span>{year}</span>
            </GridToolbar>

            {
                cols.map((col, index) => {
                    <Column key={index} field={col.field} title={col.title} />
                })
            }
        </DataGrid>
    )
}

export default ComparativeTable