import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Login() {
    const [emailInput, setEmailInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');

    const history = useNavigate();

    const handleEmailChange = (e) => {
        setEmailInput(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPasswordInput(e.target.value);
    }

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        let hardcodedCred = {
            email: 'usergroup@ea.com',
            password: 'PASSword!eduauth'
        }

        if ((emailInput == hardcodedCred.email) && (passwordInput == hardcodedCred.password)) {
            //combination is good. Log them in.
            //this token can be anything. You can use random.org to generate a random string;
            const token = '123456abcdef';
            // sessionStorage.setItem('auth-token', token);
            localStorage.clear();
            localStorage.setItem('user-token', token);
            //go to www.website.com/todo
            history('/');
        } else {
            //bad combination
            alert('wrong email or password combination');
        }
    }

    return (
        <div className="login-page">
            <h2>Login In</h2>
            <form autoComplete="off" onSubmit={handleLoginSubmit}>
                <div className="form-group">
                    <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        value={emailInput}
                        onChange={handleEmailChange}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        autoComplete="new-password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        value={passwordInput}
                        onChange={handlePasswordChange}
                    />
                </div>
                <button type="submit" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                    Submit
                </button>
            </form>
        </div>
    )
}

export default Login;