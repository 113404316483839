import { Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartTitle, ChartLegend } from '@progress/kendo-react-charts';
import { Card, CardHeader, CardTitle, CardBody, CardActions, CardImage, CardSubtitle, Avatar } from '@progress/kendo-react-layout';

const EmissionsByScopeReport = () => {
    const barData = [
        { value: 100000, color: '#FF5733' },
        { value: 150000, color: '#33FF57' },
        { value: 125000, color: '#3357FF' }
        ];
        const barCategories = ['Scope 1', 'Scope 2', 'Scope 3'];
    return (
        <Chart style={{ height: 350 }}>
            <ChartTitle text="" />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={barCategories} />
            </ChartCategoryAxis>
            <ChartSeries>
                <ChartSeriesItem
                    type="column"
                    tooltip={{ visible: true }}
                    data={barData}
                    field='value'
                    colorField="color"
                />
            </ChartSeries>
        </Chart>
    )
}

export default EmissionsByScopeReport;