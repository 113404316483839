import React from 'react'
import { useState, useEffect } from "react";
import {Grid, Cell} from "react-foundation";
import { Grid as DataGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import "hammerjs";
import {Link, useNavigate, useParams} from "react-router-dom";
import {isNullOrEmptyString} from "@progress/kendo-data-query/dist/npm/utils";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Window } from "@progress/kendo-react-dialogs";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {orderBy} from "@progress/kendo-data-query";
import {GridToolbar as Toolbar} from "@progress/kendo-react-grid/dist/npm/GridToolbar";
import axios from "axios";
import $ from "jquery";
import kitchenData from "../data/kitchen.json";
import fleetData from "../data/fleet.json";
import itData from "../data/it.json";

function AssetManagement() {
    const navigate = useNavigate();

    const _export = React.useRef(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const initialSort = [
        {
            field: "Make",
            dir: "desc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);

    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const [visible, setVisible] = React.useState(true);
    const toggleFleetDialog = () => {
        setVisible(!visible);
    };

    const toggleItDialog = () => {
        setVisible(!visible);
    };

    const toggleKitchenDialog = () => {
        setVisible(!visible);
    };

    return (
        <div className="dashboard">
            <div className="dashContainer">
                <div className="dashContainerHeader"><strong>Asset Management - All Sites</strong></div>
                <Grid className="display">
                    <Cell small={12} medium={12} large={12}>
                        <TabStrip selected={selected} onSelect={handleSelect}>
                            <TabStripTab title="Fleet Asset Listing">
                                <div>
                                    <Grid>
                                        <Cell small={12} medium={12} large={12}>
                                            <button
                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                onClick={toggleFleetDialog}
                                            >
                                                Add New Fleet Asset
                                            </button>
                                            {!visible && (
                                                <Window title={"Status"} onClose={toggleFleetDialog} initialHeight={550} initialWidth={450} modal={true}>
                                                    <form className="k-form">
                                                        <fieldset>
                                                            <legend>Fleet Vehicle Details</legend>

                                                            <label className="k-form-field">
                                                                <span>Vehicle Registration</span>
                                                                <input type="text"  className="k-input" placeholder="Registration No." />
                                                            </label>
                                                            <label className="k-form-field">
                                                                <span>Make</span>
                                                                <input type="text" className="k-input" placeholder="Make" />
                                                            </label>
                                                            <label className="k-form-field">
                                                                <span>Model</span>
                                                                <input type="text" className="k-input" placeholder="Model" />
                                                            </label>
                                                            <label className="k-form-field">
                                                                <span>Engine Size (cc)</span>
                                                                <input type="tel" className="k-input" placeholder="Engine Size" />
                                                            </label>
                                                            <label className="k-form-field">
                                                                <span>Year</span>
                                                                <input type="tel" className="k-input" placeholder="Year" />
                                                            </label>
                                                            <label className="k-form-field">
                                                                <span>Route Number</span>
                                                                <input type="tel" className="k-input" placeholder="Route Number" />
                                                            </label>
                                                        </fieldset>

                                                        <div className="text-right">
                                                            <button
                                                                type="button"
                                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                                onClick={toggleFleetDialog}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                                onClick={toggleFleetDialog}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </form>
                                                </Window>
                                            )}
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <div className="notesHeading">
                                                Expected daily kgCO2e is calculated using the Vehicle Miles per Route, and multiply out by the kgCO2e/unit conversion factor using the relevant yearly conversion factor for the vehicle type.  Expected kgCO2e = (miles * kgCO2e/unit).
                                            </div>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <ExcelExport data={fleetData} ref={_export}>
                                            <DataGrid
                                                style={{
                                                    height: "700px",
                                                }}
                                                data={orderBy(fleetData, sort)}
                                                sortable={true}
                                                sort={sort}
                                                onSortChange={(e) => {
                                                    setSort(e.sort);
                                                }}
                                            >
                                                <Toolbar>
                                                    <button
                                                        title="Export Excel"
                                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                        onClick={excelExport}
                                                    >
                                                        Export to Excel
                                                    </button>
                                                </Toolbar>
                                                <Column field="Make" title="Make" />
                                                <Column field="Model" title="Model" />
                                                <Column field="Type" title="Type" />
                                                <Column field="FuelType" title="Fuel Type" />
                                                <Column field="Engine" title="Engine" />
                                                <Column field="Reg" title="Registration" />
                                                <Column field="BusId" title="Bus ID" />
                                                <Column field="NoSeats" title="No. Seats" />
                                                <Column field="NoStudents" title="No. Pupils" />
                                                <Column field="Mileage" title="Mileage" />
                                                <Column field="Year" title="Year" />
                                                <Column field="Route" title="Route" />
                                                <Column field="Miles" title="Miles" />
                                                <Column field="Conversion" title="Conversion" />
                                                <Column field="ExpectedCO2" title="ExpectedCO2" />
                                                <Column field="Delivery" title="Delivery" />
                                                <Column field="DeliveryMiles" title="DeliveryMiles" />
                                                <Column field="DeliveryCO2" title="Delivery CO2" />
                                                <Column field="Subcontractor" title="Subcontractor" />
                                                <Column field="SubcontractorName" title="Subcontractor Name" />
                                            </DataGrid>
                                            </ExcelExport>
                                        </Cell>
                                    </Grid>
                                </div>
                            </TabStripTab>
                            <TabStripTab title="IT Asset Listing">
                                <div>
                                    <Grid>
                                        <Cell small={12} medium={12} large={12}>
                                            <button
                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                onClick={toggleItDialog}
                                            >
                                                Add New IT Asset
                                            </button>
                                            {!visible && (
                                                <Window title={"Status"} onClose={toggleItDialog} initialHeight={550} initialWidth={450} modal={true}>
                                                    <form className="k-form">
                                                        <fieldset>
                                                            <legend>IT User Details</legend>

                                                            <label className="k-form-field">
                                                                <span>Make</span>
                                                                <input type="text" className="k-input" placeholder="Make" />
                                                            </label>
                                                            <label className="k-form-field">
                                                                <span>Model</span>
                                                                <input type="text" className="k-input" placeholder="Model" />
                                                            </label>
                                                            <label className="k-form-field">
                                                                <span>Year</span>
                                                                <input type="tel" className="k-input" placeholder="Year" />
                                                            </label>
                                                            <label className="k-form-field">
                                                                <span>Asset Type</span>
                                                                <select className="k-select" placeholder="Asset Type Selection">
                                                                    <option value="1">Computer</option>
                                                                    <option value="2">Mobile Device</option>
                                                                    <option value="3">Projector</option>
                                                                </select>
                                                            </label>
                                                        </fieldset>

                                                        <div className="text-right">
                                                            <button
                                                                type="button"
                                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                                onClick={toggleItDialog}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                                onClick={toggleItDialog}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </form>
                                                </Window>
                                            )}
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <div className="notesHeading">
                                                Expected daily kgCO2e is calculated using the expected Watt/usage per day and converting kWh, using the expected daily usage as 6 hours use in classes, 2 in sleep mode, and 16 hours switched off. Expected kWh/day is then multiplied out by the kgCO2e/unit conversion factor using the relevant yearly conversion factor for the energy type Electricity.  Watt to kWh = (daily Watt usage for type of use * Amp / 1000). Expected kgCO2e = (kWh * kgCO2e/unit).
                                            </div>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <ExcelExport data={itData} ref={_export}>
                                            <DataGrid
                                                style={{
                                                    height: "700px",
                                                }}
                                                data={orderBy(itData, sort)}
                                                sortable={true}
                                                sort={sort}
                                                onSortChange={(e) => {
                                                    setSort(e.sort);
                                                }}
                                            >
                                                <Toolbar>
                                                    <button
                                                        title="Export Excel"
                                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                        onClick={excelExport}
                                                    >
                                                        Export to Excel
                                                    </button>
                                                </Toolbar>
                                                <Column field="Category" title="Category" />
                                                <Column field="SubCategory" title="Sub Category" />
                                                <Column field="Make" title="Make" />
                                                <Column field="Model" title="Model" />
                                                <Column field="Year" title="Year" />
                                                <Column field="Ownership" title="Ownership" />
                                                <Column field="Count" title="Count" />
                                                <Column field="Cost" title="Cost (£)" />
                                                <Column field="RemainingTime" title="Remaining Time (Years)" />
                                                <Column field="ConversionActive" title="Conversion Active" />
                                                <Column field="ConversionSleep" title="Conversion Sleep" />
                                                <Column field="ConversionOff" title="Conversion Off" />
                                                <Column field="HoursBreakdown" title="Hours Breakdown" />
                                                <Column field="DailyKwh" title="Daily KWh" />
                                                <Column field="Conversion" title="Conversion (kgCO2e)" />
                                                <Column field="ExpectedCo2Per" title="Expected kgCO2e Per Unit" />
                                                <Column field="ExpectedCo2Total" title="Expected kgCO2e Total" />
                                            </DataGrid>
                                            </ExcelExport>
                                        </Cell>
                                    </Grid>
                                </div>
                            </TabStripTab>
                            <TabStripTab title="Kitchen Asset Listing">
                                <div>
                                    <Grid>
                                        <Cell small={12} medium={12} large={12}>
                                            <button
                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                onClick={toggleKitchenDialog}
                                            >
                                                Add New Kitchen Asset
                                            </button>
                                            {!visible && (
                                                <Window title={"Status"} onClose={toggleKitchenDialog} initialHeight={550} initialWidth={450} modal={true}>
                                                    <form className="k-form">
                                                        <fieldset>
                                                            <legend>Kitchen Appliance Details</legend>

                                                            <label className="k-form-field">
                                                                <span>Make</span>
                                                                <input type="text" className="k-input" placeholder="Make" />
                                                            </label>
                                                            <label className="k-form-field">
                                                                <span>Model</span>
                                                                <input type="text" className="k-input" placeholder="Model" />
                                                            </label>
                                                            <label className="k-form-field">
                                                                <span>Year</span>
                                                                <input type="tel" className="k-input" placeholder="Year" />
                                                            </label>
                                                            <label className="k-form-field">
                                                                <span>Asset Type</span>
                                                                <select className="k-select" placeholder="Asset Type Selection">
                                                                    <option value="1">Oven</option>
                                                                    <option value="2">Fridge</option>
                                                                    <option value="3">Fryer</option>
                                                                </select>
                                                            </label>
                                                        </fieldset>

                                                        <div className="text-right">
                                                            <button
                                                                type="button"
                                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                                onClick={toggleKitchenDialog}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                                onClick={toggleKitchenDialog}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </form>
                                                </Window>
                                            )}
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <div className="notesHeading">
                                                Expected daily kgCO2e is calculated using the expected kWh per hour, using the expected daily usage as determine per equipment type, some requiring 24 hours run time, other shorter periods as per table. Expected kWh/unit is then multiplied out by the kgCO2e/unit conversion factor using the relevant yearly conversion factor for the energy type Electricity.  Expected kgCO2e = (kWh * kgCO2e/unit).
                                            </div>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <ExcelExport data={kitchenData} ref={_export}>
                                            <DataGrid
                                                style={{
                                                    height: "700px",
                                                }}
                                                data={orderBy(kitchenData, sort)}
                                                sortable={true}
                                                sort={sort}
                                                onSortChange={(e) => {
                                                    setSort(e.sort);
                                                }}
                                            >
                                                <Toolbar>
                                                    <button
                                                        title="Export Excel"
                                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                        onClick={excelExport}
                                                    >
                                                        Export to Excel
                                                    </button>
                                                </Toolbar>
                                                <Column field="Equipment" title="Equipment" />
                                                <Column field="Make" title="Make" />
                                                <Column field="Model" title="Model" />
                                                <Column field="AverageKwh" title="Average KWh" />
                                                <Column field="DailyRunningTime" title="Daily Running Time (Hours)" />
                                                <Column field="DailyKwh" title="Daily KWh" />
                                                <Column field="Conversion" title="Conversion" />
                                                <Column field="DailyKgco2" title="Daily KgCO2" />
                                            </DataGrid>
                                            </ExcelExport>
                                        </Cell>
                                    </Grid>
                                </div>
                            </TabStripTab>
                        </TabStrip>
                    </Cell>
                </Grid>
            </div>
        </div>
    );
}

export default AssetManagement;