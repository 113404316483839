import React from 'react'
import { useEffect } from "react";
import {Grid, Cell} from "react-foundation";
import { Grid as DataGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import "hammerjs";
import {Link, useNavigate, useParams} from "react-router-dom";
import {orderBy} from "@progress/kendo-data-query";
import axiosApi from "../api/axiosInstance";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {GridToolbar as Toolbar} from "@progress/kendo-react-grid/dist/npm/GridToolbar";
import { filterBy } from "@progress/kendo-data-query";

function CompanyTargets() {
    const [companyTargetsList, setCompanyTargetsList] = React.useState([]);
    const _export = React.useRef(null);
    const [filter, setFilter] = React.useState();
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };
    const initialSort = [
        {
            field: "name",
            dir: "desc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);

    const sortChange = (event) => {
        setCompanyTargetsList(getCompanyTargets(event.sort));
        setSort(event.sort);
    };
    const getCompanyTargets = (sort) => {
        return orderBy(companyTargetsList, sort);
    };

    const [visible, setVisible] = React.useState(true);

    useEffect(() => {
        const loadCompanyTargets = async () => {
            axiosApi.get(
                '/company-targets',
                {
                    params: {
                    }
                }).then(function (response) {

               // console.log(response.data);
                if (response['data']['data']['list'].length > 0) {
                    setCompanyTargetsList(response['data']['data']['list']);
                }
            });
        }
        loadCompanyTargets();
    }, []);

    return (
        <div className="dashboard">
            <div className="dashContainer">
                <div className="dashContainerHeader"><strong>All Company Targets</strong></div>
                <Grid className="display"
                      style={{
                        height: "800px",
                        }}

                >

                    <Cell small={12} medium={12} large={12}>
                        <div>
                            <Link to={'/add-company-targets'}><button
                                title="Add Company Targets"
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            >
                                Add Company Targets
                            </button></Link>
                        </div>
                        <ExcelExport data={companyTargetsList} ref={_export}>
                            <DataGrid
                                style={{
                                    height: "800px",
                                }}

                                data={filterBy(companyTargetsList, filter)}
                                filterable={true}
                                filter={filter}
                                onFilterChange={(e) => setFilter(e.filter)}
                                sortable={true}
                                sort={sort}
                                onSortChange={sortChange}
                            >
                                <Toolbar>
                                    <button
                                        title="Export Excel"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        onClick={excelExport}
                                    >
                                        Export to Excel
                                    </button>
                                </Toolbar>
                                    <Column field="id" title="Id" width="150px"/>
                                    <Column field="start_date" title="Start Date" />
                                    <Column field="end_date" title="End Date" />
                                    <Column field="target_measurement_type" title="Target Measurement Type" />
                                    <Column field="energy_type" title="Energy Type" />
                                    <Column field="percentage_target" title="Percentage Target (%)" />
                                    <Column field="target_goal" title="Target Goal" />
                            </DataGrid>
                        </ExcelExport>
                    </Cell>
                </Grid>
            </div>
        </div>
    );

}

export default CompanyTargets;