import * as React from "react";
import {Grid, Cell} from "react-foundation";
import { Grid as DataGrid, GridColumn as Column, GridToolbar as Toolbar } from "@progress/kendo-react-grid";
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    Sparkline,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartLegend,
    ChartTitle,
    ChartCategoryAxis,
    ChartCategoryAxisTitle,
    ChartCategoryAxisItem,
} from '@progress/kendo-react-charts';
// import { exportImage } from "@progress/kendo-drawing";
// import { transform } from "@progress/kendo-drawing/geometry";
// import { saveAs } from "@progress/kendo-file-saver";
import "hammerjs";
import {Link, useNavigate, useParams} from "react-router-dom";
import {isNullOrEmptyString} from "@progress/kendo-data-query/dist/npm/utils";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {orderBy} from "@progress/kendo-data-query";
import axios from "axios";
import $ from "jquery";
import {useEffect, useState} from "react";
import kitchenData from "../data/kitchen.json";
import itData from "../data/it.json";

function ApplianceLanding() {
    const navigate = useNavigate();

    const { id } = useParams();
    const { site } = useParams();

    const [temperatureDataChart, setTemperatureDataChart] = useState([]);
    const [itDataChart, setItDataChart] = useState([]);


    // const chart = React.createRef();
    // const onExportVisual = () => {
    //     let chartRefValue = chart.current;
    //     const chartVisual = exportImage(chartRefValue);
    //     const center = chartVisual.bbox().center();
    //
    //     // Rotate 45 degrees around center
    //     chartVisual.transform(transform().rotate(45, center));
    //     if (chartVisual) {
    //         exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "chart.png"));
    //     }
    // };

    let appType = '';
    if(id == 1) {
        appType = 'IT';
    } else if(id == 2) {
        appType = 'Kitchen';
    } else {
        appType = 'Appliance';
    }

    const _export = React.useRef(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const initialSort = [
        {
            field: "Date",
            dir: "desc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);

    const generateSeries = () => {
        const series = [];
        for (let idx = 0; idx < 500; idx++) {
            series.push({
                value: Math.floor(Math.random() * 100) + 1,
                category: new Date(2021, 0, idx),
            });
        }
        return series;
    };
    const series = generateSeries();

    const generateMinSeries = () => {
        const seriesMinTemp = [];
        for (let idx = 0; idx < 500; idx++) {
            seriesMinTemp.push({
                value: Math.floor(Math.random() * 12) + 1,
                category: new Date(2021, 0, idx),
            });
        }
        return seriesMinTemp;
    };
    const seriesMinTemp = generateMinSeries();

    const generateMaxSeries = () => {
        const seriesMaxTemp = [];
        for (let idx = 0; idx < 500; idx++) {
            seriesMaxTemp.push({
                value: Math.floor(Math.random() * 25) + 1,
                category: new Date(2021, 0, idx),
            });
        }
        return seriesMaxTemp;
    };
    const seriesMaxTemp = generateMaxSeries();
    const categoryAxisMax = new Date(2021, 1, 0);
    const categoryAxisMaxDivisions = 10;

    const value = new Date();

    const valueAxis = [
        {
            name: "rain",
            color: "#007eff",
            min: 0,
            max: 60,
        },
        {
            name: "wind",
            color: "#73c100",
            min: 0,
            max: 60,
        },
        {
            name: "temp",
            min: -30,
            max: 30,
        },
    ];


    const meterEntries = [{
        "Meter" : 9364913476,
        "EnergyType" : "Electric",
        "Date" : "2022-12-02",
        "Amount" : 29,
        "CO2Total" : 12.254,
        "CO2TotalPerMsq" : 0.256,
        "CO2TotalPerHund" : 0.0356,
        "Kwh" : 29
    },{
        "Meter" : 9364913476,
        "EnergyType" : "Electric",
        "Date" : "2022-12-03",
        "Amount" : 23,
        "CO2Total" : 11.283,
        "CO2TotalPerMsq" : 0.190,
        "CO2TotalPerHund" : 0.029,
        "Kwh" : 23
    },{
        "Meter" : 9364913476,
        "EnergyType" : "Electric",
        "Date" : "2022-12-04",
        "Amount" : 25,
        "CO2Total" : 11.932,
        "CO2TotalPerMsq" : 0.194,
        "CO2TotalPerHund" : 0.032,
        "Kwh" : 25
    },{
        "Meter" : 9364913476,
        "EnergyType" : "Electric",
        "Date" : "2022-12-05",
        "Amount" : 25,
        "CO2Total" : 11.932,
        "CO2TotalPerMsq" : 0.194,
        "CO2TotalPerHund" : 0.032,
        "Kwh" : 25
    },{
        "Meter" : 9364913476,
        "EnergyType" : "Electric",
        "Date" : "2022-12-06",
        "Amount" : 25,
        "CO2Total" : 11.932,
        "CO2TotalPerMsq" : 0.194,
        "CO2TotalPerHund" : 0.032,
        "Kwh" : 25
    },{
        "Meter" : 9364913476,
        "EnergyType" : "Electric",
        "Date" : "2022-12-07",
        "Amount" : 25,
        "CO2Total" : 11.932,
        "CO2TotalPerMsq" : 0.194,
        "CO2TotalPerHund" : 0.032,
        "Kwh" : 25
    },{
        "Meter" : 9364913476,
        "EnergyType" : "Electric",
        "Date" : "2022-12-08",
        "Amount" : 25,
        "CO2Total" : 11.932,
        "CO2TotalPerMsq" : 0.194,
        "CO2TotalPerHund" : 0.032,
        "Kwh" : 25
    },{
        "Meter" : 9364913476,
        "EnergyType" : "Electric",
        "Date" : "2022-12-09",
        "Amount" : 25,
        "CO2Total" : 11.932,
        "CO2TotalPerMsq" : 0.194,
        "CO2TotalPerHund" : 0.032,
        "Kwh" : 25
    }];

    const loadEnergyUsageVsTempatureGraph = async () => {

        const temperature_series = [];
        const it_series = [];

        let start_date = '2016-09-01';
        let end_date = '2023-03-31';
        let site_id = 1230;
        let url = 'https://staging-api.sustainiq.co.uk/api/pillar/2/site-energy-meter-entries';
        let params = {
            site_id: site_id,
            start_date: start_date,
            end_date: end_date,
            energy_type_id: id
        };

        //get temperature data
        axios.get(
            'https://staging-api.sustainiq.co.uk/api/pillar/2/site-environment-meter-entries',
            {
                params: {
                    site_id: site_id,
                    start_date: start_date,
                    end_date: end_date
                }
            }
        ).then(function (response) {
            let data = response['data'];
            let entries = [];

            if(data['data']['summary']['by_day'] !== undefined) {
                entries = data['data']['summary']['by_day'];
            } else if(data['data']['summary']['by_month'] !== undefined) {
                entries = data['data']['summary']['by_month'];
            }

            if (entries.length > 0) {
                for (let i = 0; i < entries.length; i++) {
                    let entry = entries[i];

                    temperature_series.push({
                        value: entry['average_amount'],
                        category: entry['date'],
                    });

                    let times_by;
                    if(data['data']['summary']['by_day'] !== undefined) {
                        times_by = 1;
                    } else if(data['data']['summary']['by_month'] !== undefined) {
                        times_by = 30;
                    }

                    let total_kwh = 0;
                    let data2 = id == 1 ? itData : kitchenData;

                    for(let j = 0; j < Object.keys(data2).length; j++) {
                        total_kwh += data2[j]['DailyKwh'] * times_by;
                    }

                    it_series.push({
                        value: total_kwh,
                        category: entry['date'],
                    });
                }

                setTemperatureDataChart(temperature_series);
                setItDataChart(it_series);
            } else {
                setTemperatureDataChart([]);
                setItDataChart([]);
            }
        });
    };

    useEffect(() => {
        loadEnergyUsageVsTempatureGraph();
    }, [id]);

    return (
        <Grid>
            <Cell small={12} medium={2} large={2}>
                <div className="energyNavigation">
                    <h5>Scope 1 Emission Sources</h5>
                    <ul>
                        <li>
                            <Link to={'/energy/'+site+'/2'} className="nav-link">Natural Gas</Link>
                        </li>
                        <li>
                            <Link to={'/energy/'+site+'/15'} className="nav-link">Burning Oil</Link>
                        </li>
                        <li>
                            <Link to={'/energy/'+site+'/16'} className="nav-link">Gas Oil</Link>
                        </li>
                    </ul>
                    <hr/>
                    <h5>Scope 2 Emission Sources</h5>
                    <ul>
                        <li>
                            <Link to={'/energy/'+site+'/1'} className="nav-link">Electric</Link>
                        </li>
                    </ul>
                    <hr/>
                    <h5>Usage Type Sub-Sections</h5>
                    <ul className="submenuNav">
                        <li>
                            <Link to={'/meter/'+site+'/1'} className="nav-link">IT</Link>
                        </li>
                        <li>
                            <Link to={'/meter/'+site+'/2'} className="nav-link">Kitchen</Link>
                        </li>
                    </ul>
                    <hr/>
                    <h5>Uploads</h5>
                    <ul>
                        <li>
                            <Link to={'/input/energy/'+site} className="nav-link">Energy Meter Upload</Link>
                        </li>
                    </ul>
                    <hr/>
                    <h5>Digital Twin</h5>
                    <ul>
                        <li>
                            <Link to={'/twin/energy/'+site} className="nav-link">Digital Twin Overview</Link>
                        </li>
                    </ul>
                </div>
            </Cell>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion">
                                <Link to={'/dashboard/energy/'+site} className="linkBack">&#11164; Back to Energy Dashboard</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion padAlignRight">
                                <p>Start and End Dates:</p>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date(2016,8,1,0,0,0)} defaultShow={false} format={"dd-MM-yyyy"} name="startDate" id="startDate" />
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date()} defaultShow={false} format={"dd-MM-yyyy"} name="endDate" id="endDate" />
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>{ appType } Electricity</strong></div>
                        <Grid className="display">
                            <Cell small={12} medium={12} large={12}>
                                <Chart
                                    pannable={{
                                        lock: "y",
                                    }}
                                    zoomable={{
                                        mousewheel: {
                                            lock: "y",
                                        },
                                        selection: {
                                            lock: "y",
                                        },
                                    }}
                                >
                                    <ChartTitle text={appType+" Energy Usage vs Temperature"} />
                                    <ChartLegend position="bottom" />
                                    <ChartCategoryAxis>
                                        <ChartCategoryAxisItem
                                            max={categoryAxisMax}
                                            maxDivisions={categoryAxisMaxDivisions}
                                        />
                                    </ChartCategoryAxis>
                                    <ChartValueAxis>
                                        <ChartValueAxisItem
                                            title={{
                                                text: "kWh",
                                                position: "top"
                                            }}
                                            labels={{
                                                visible: true,
                                                format: "#.00",
                                            }}
                                            name="Energy Value"
                                        />
                                        <ChartValueAxisItem
                                            title={{
                                                text: "°C",
                                                position: "top"
                                            }}
                                            labels={{
                                                visible: true,
                                                format: "#.00",
                                            }}
                                            name="Temperature"
                                        />
                                    </ChartValueAxis>
                                    <ChartSeries>
                                        <ChartSeriesItem data={itDataChart} axis="Energy Value" field="value" categoryField="category" name={appType+" Energy (kWh)"} />
                                        <ChartSeriesItem data={temperatureDataChart} axis="Temperature" type="line" field="value" categoryField="category" name="Internal Temp (°C)" />
                                    </ChartSeries>
                                </Chart>
                            </Cell>
                            <Cell small={12} medium={12} large={12}>
                                <ExcelExport data={id == 1 ? itData : kitchenData} ref={_export}>
                                    <DataGrid
                                        style={{
                                            height: "400px",
                                        }}
                                        data={orderBy(id == 1 ? itData : kitchenData, sort)}
                                        sortable={true}
                                        sort={sort}
                                        onSortChange={(e) => {
                                            setSort(e.sort);
                                        }}
                                    >
                                        <Toolbar>
                                            <button
                                                title="Export Excel"
                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                onClick={excelExport}
                                            >
                                                Export to Excel
                                            </button>
                                        </Toolbar>
                                    </DataGrid>
                                </ExcelExport>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default ApplianceLanding;