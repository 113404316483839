import {Link, useParams} from "react-router-dom";
import {Cell} from "react-foundation";
import * as React from "react";
const WaterNavbar = () => {
    const params = useParams();
    const siteid = params['site'];
    const id  = params['id'] ?? '';

    return (
        <Cell small={12} medium={2} large={2}>
            <div className="waterNavigation">
                <h5>Inputs</h5>
                <ul>
                    <li>
                        <Link to={'/water-meters/' + siteid} className="nav-link">Water Meters</Link>
                    </li>
                </ul>
                <ul>
                    <li>
                        <Link to={'/water-meter-entries/'+ siteid + (id ? '/'+id : '')} className="nav-link">Water Meter Entries</Link>
                    </li>
                </ul>
                <ul>
                    <li>
                        <Link to={'/input/water/' + siteid} className="nav-link">Water Meter Entry Upload</Link>
                    </li>
                </ul>
            </div>
        </Cell>
    )
}

export default WaterNavbar