import React from 'react'
import {Grid, Cell} from "react-foundation";
import "hammerjs";
import {Link, useNavigate, useParams} from "react-router-dom";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import {useEffect, useState} from "react";
import axiosApi from "../api/axiosInstance";
import {parseNumber} from "./form/format-helper";
import {formatNumber} from "@progress/kendo-intl";

function CompanyLanding() {
    const navigate = useNavigate();

    const [kgcoData, setKgcoData] = useState(0);
    const [kgcoeData, setKgcoeData] = useState(0);
    const [kwhData, setKwhData] = useState(0);
    const [tcoData, setTcoData] = useState(0);

    const [baseKwhData, setBaseKwhData] = useState(0);
    const [baseKgcoeData, setBaseKgcoeData] = useState(0);
    const [baseKgcoData, setBaseKgcoData] = useState(0);
    const [baseTcoData, setBaseTcoData] = useState(0);

    const [totalKgcoData, setTotalKgcoData] = useState(0);
    const [totalKgcoeData, setTotalKgcoeData] = useState(0);
    const [totalKwhData, setTotalKwhData] = useState(0);
    const [totalTcoData, setTotalTcoData] = useState(0);

    const [energyData, setEnergyData] = useState([]);
    const [baseEnergyData, setBaseEnergyData] = useState([]);

    const [ghgEmissions, setGhgEmissions] = useState([]);

    const [transportKgcoe, setTransportKgcoe] = useState([]);
    const [transportTcoe, setTransportTcoe] = useState([]);
    const [transportDist, setTransportDist] = useState([]);

    const siteid = useParams()['site'];
    const [siteName, setSiteName] = useState('');
    const [siteData, setSiteData] = useState([]);

    const startDate = new Date("2022-09-01");
    const endDate = new Date("2023-08-31");
    const baselineStartDate = new Date("2019-09-01");
    const baselineEndDate = new Date("2020-08-31");

    const baseLineYear = '2019/20';
    const currentYear = '2022/23';


    useEffect(() => {

        const ghg_emissions_data = [];
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + siteid,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                    setSiteData(response['data']['data']);
                }
            });
        }

        loadSite();


        const loadEntriesSection = async () => {
            axiosApi.get(
                '/pillar/2/site-energy-meter-entries/summary',
                {
                    params: {
                        site_id: siteid,
                        start_date: startDate,
                        end_date: endDate,
                        by_energy_type: 1
                    }
                }).then(function (response) {
                let kwhTotal = 0;
                let kgco2Total = 0;
                let kgco2eTotal = 0;
                let tco2eTotal = 0;
                if (response['data']['data']['summary']['overall'].length > 0) {
                    for (let i = 0; i < response['data']['data']['summary']['overall'].length; i++) {
                        let measurement = response['data']['data']['summary']['overall'][i]['measurement'];
                        let amount = parseNumber(response['data']['data']['summary']['overall'][i]['total_amount']);
                        if(measurement == 'kgCO2') {
                            kgco2Total = amount;
                        } else if(measurement == 'kgCO2e') {
                            kgco2eTotal = amount;
                            tco2eTotal = amount/1000;
                        } else if(measurement == 'Kilowatt Hour (Gross CV)') {
                            kwhTotal = amount
                        }
                    }
                }
                setKgcoData(kgco2Total);
                setKgcoeData(kgco2eTotal);
                setTcoData(tco2eTotal);
                setKwhData(kwhTotal);

                let kgChartList = [];
                if(response['data']['data']['summary']['by_energy_type'].length > 0) {
                    for(var i = 0; i < response['data']['data']['summary']['by_energy_type'].length; i++) {
                        let type= response['data']['data']['summary']['by_energy_type'][i]['energy_type'];
                        let kgco2e = parseNumber(response['data']['data']['summary']['by_energy_type'][i]['ghg_emissions'][1]['total_amount']);
                        let usage = parseNumber(response['data']['data']['summary']['by_energy_type'][i]['ghg_emissions'][2]['total_amount']);
                        kgChartList.push({
                            kind: type,
                            kgco2e: kgco2e,
                            usage: usage
                        });
                        ghg_emissions_data[type] = ghg_emissions_data[type]??[];
                        ghg_emissions_data[type]['kgco2e'] = kgco2e;
                        ghg_emissions_data[type]['tco2e'] = kgco2e/1000;
                        ghg_emissions_data[type]['usage'] = usage;

                    }
                }

                setEnergyData(kgChartList);
            });
        }
        loadEntriesSection();

        const loadBaseLineSection = async () => {
            axiosApi.get(
                '/pillar/2/site-energy-meter-entries/summary',
                {
                    params: {
                        site_id: siteid,
                        start_date: baselineStartDate,
                        end_date: baselineEndDate,
                        by_energy_type: 1
                    }
                }).then(function (response) {
                let kwhTotal = 0;
                let kgco2Total = 0;
                let kgco2eTotal = 0;
                let tco2eTotal = 0;
                if (response['data']['data']['summary']['overall'].length > 0) {
                    for (let i = 0; i < response['data']['data']['summary']['overall'].length; i++) {
                        let measurement = response['data']['data']['summary']['overall'][i]['measurement'];
                        let amount = parseNumber(response['data']['data']['summary']['overall'][i]['total_amount']);
                        if(measurement == 'kgCO2') {
                            kgco2Total = amount;
                        } else if(measurement == 'kgCO2e') {
                            kgco2eTotal = amount;
                            tco2eTotal = amount/1000;
                        } else if(measurement == 'Kilowatt Hour (Gross CV)') {
                            kwhTotal = amount
                        }
                    }
                }

                setBaseKgcoData(kgco2Total);
                setBaseKgcoeData(kgco2eTotal);
                setBaseTcoData(tco2eTotal);
                setBaseKwhData(kwhTotal);

                let kgChartList = [];
                if(response['data']['data']['summary']['by_energy_type'].length > 0) {
                    for(var i = 0; i < response['data']['data']['summary']['by_energy_type'].length; i++) {
                        let type = response['data']['data']['summary']['by_energy_type'][i]['energy_type'];
                        let kgco2e = parseNumber(response['data']['data']['summary']['by_energy_type'][i]['ghg_emissions'][1]['total_amount']);
                        let usage = parseNumber(response['data']['data']['summary']['by_energy_type'][i]['ghg_emissions'][2]['total_amount']);

                        ghg_emissions_data[type] = ghg_emissions_data[type]??[];
                        ghg_emissions_data[type]['basekgco2e'] = kgco2e;
                        ghg_emissions_data[type]['baseusage'] = usage;
                        ghg_emissions_data[type]['basetco2e'] = kgco2e/1000;

                    }
                }

                setBaseEnergyData(kgChartList);

            });
        }
        loadBaseLineSection();

        setGhgEmissions(ghg_emissions_data);

        console.log(ghg_emissions_data);

        const loadTotalSummarySection = async () => {
            axiosApi.get(
                '/pillar/2/site-energy-meter-entries/summary',
                {
                    params: {
                        site_id: siteid,
                        start_date: '2018-09-01',
                        end_date: new Date()
                    }
                }).then(function (response) {
                let kwhTotal = 0;
                let kgco2Total = 0;
                let kgco2eTotal = 0;
                let tco2eTotal = 0;

                if (response['data']['data']['summary']['overall'].length > 0) {
                    for (let i = 0; i < response['data']['data']['summary']['overall'].length; i++) {
                        let measurement = response['data']['data']['summary']['overall'][i]['measurement'];
                        let amount = parseNumber(response['data']['data']['summary']['overall'][i]['total_amount']);
                        if(measurement == 'kgCO2') {
                            kgco2Total = amount;
                        } else if(measurement == 'kgCO2e') {
                            kgco2eTotal = amount;
                            tco2eTotal = amount/1000;
                        } else if(measurement == 'Kilowatt Hour (Gross CV)') {
                            kwhTotal = amount
                        }
                    }
                }

                setTotalKgcoData(kgco2Total);
                setTotalKgcoeData(kgco2eTotal);
                setTotalTcoData(tco2eTotal);
                setTotalKwhData(kwhTotal);

            });
        }
        loadTotalSummarySection();

    }, []);

    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const _export = React.useRef(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const initialSort = [
        {
            field: "Make",
            dir: "desc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);

    const labelContent = (props) => {
        let formatedNumber = Number(props.dataItem.usage).toLocaleString(undefined, {
            // style: "percent",
            minimumFractionDigits: 2,
        });
        let contribNumber = Number(props.dataItem.share).toLocaleString(undefined, {
            minimumFractionDigits: 2,
        });
        return `${props.dataItem.kind}: ${formatedNumber}\r\n(${contribNumber} kgCO2)`;
    };

    return (
        <div className="dashboard">
            <div className="dashContainer">
                <div className="dashContainerHeader"><strong>Site Summary</strong> <span className="dataSubNote">for Date Range 1st September 2016 - 15th April 2024</span></div>
                <Grid className="display">
                    <Cell small={12} medium={3} large={3}>
                        <div className="quickSelectTab">
                            <Link to={'/sitelanding/' + siteid}><h4>siteName</h4></Link>
                            <hr/>
                            <div className="panelbar-wrapper">
                                <Link to={'/sitelanding/' + siteid}>
                                    <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">
                                        View School Breakdown >
                                    </button>
                                </Link>
                            </div>
                            <hr/>
                            <table className="statsTable">
                                <tr>
                                    <td>Total kgCO2e:</td>
                                    <td>{ formatNumber(totalKgcoeData , 'n2') }</td>
                                </tr>
                                <tr>
                                    <td>Total tCO2e:</td>
                                    <td>{ formatNumber(totalTcoData, 'n2') }</td>
                                </tr>
                                <tr>
                                    <td>Total kWh:</td>
                                    <td>{ formatNumber(totalKwhData, 'n2') }</td>
                                </tr>
                            </table>

                        </div>
                    </Cell>
                    <Cell small={12} medium={9} large={9}>
                        <TabStrip selected={selected} onSelect={handleSelect}>
                            <TabStripTab title="Location Overview">
                                <div>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Section</th>
                                            <th>Values:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Building Identifier:</td>
                                            <td>{siteData['reference']}</td>
                                        </tr>
                                        <tr>
                                            <td>Building Address:</td>
                                            <td>{siteData['full_address']}</td>
                                        </tr>
                                        <tr>
                                            <td>Floor Area (GIA):</td>
                                            <td>{siteData['floor_area']}</td>
                                        </tr>
                                        <tr>
                                            <td>Current Occupier:</td>
                                            <td>Controlled</td>
                                        </tr>
                                        <tr>
                                            <td>Building Use:</td>
                                            <td>{siteData['site_category']}</td>
                                        </tr>
                                        {/*
                                        <tr>
                                            <td>Kitchen Energy Source:</td>
                                            <td>Electric</td>
                                        </tr>
                                        <tr>
                                            <td>Kitchen Floor Area (GIA):</td>
                                            <td>325</td>
                                        </tr> */}
                                        {energyData.map((item) =>
                                            <tr>
                                                <td>{currentYear} {item.kind} Consumption:</td>
                                                <td>{formatNumber(item.usage, 'n2')}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td>{currentYear} Total Current Consumption:</td>
                                            <td>{ formatNumber(kwhData ,'n2')}</td>
                                        </tr>
                                        <tr>
                                            <td>{baseLineYear} Total Baseline Consumption:</td>
                                            <td>{ formatNumber(baseKwhData ,'n2') }</td>
                                        </tr>
                                        <tr>
                                            <td>{ baseKwhData > kwhData ? 'Reduction' : 'Increase'  }  from Baseline:</td>
                                            <td>{ formatNumber(kwhData, 'n2') } KWh ({ ((baseKwhData / kwhData) * 100).toFixed(2) }%)</td>
                                        </tr>

                                        {/*<tr>
                                            <td>Allocated Target Consumption:</td>
                                            <td>1398785 KWh (29.7%)</td>
                                        </tr>
                                        <tr>
                                            <td>Current Target Status:</td>
                                            <td>Target Achieved</td>
                                        </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </TabStripTab>
                            {/*}
                            <TabStripTab title="Building">
                                <div>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Section</th>
                                            <th>Values:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Building 1:</td>
                                            <td>Block 1</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Build:</td>
                                            <td>21st June 1976</td>
                                        </tr>
                                        <tr>
                                            <td>Glazing Type:</td>
                                            <td>Single</td>
                                        </tr>
                                        <tr>
                                            <td>Insulated:</td>
                                            <td>No</td>
                                        </tr>
                                        <tr>
                                            <td>Kitchen:</td>
                                            <td>No</td>
                                        </tr>
                                        <tr>
                                            <td>Meters:</td>
                                            <td>
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th>Meter ID / Location:</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Sensor 08 - Block 1 corridor room 70 (7dc9925a-b4f6-404b-9786-5fa655592ca3)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 09 - Block 1 upstairs room 120 (dc01a158-187e-4778-93a5-653c04c0b32a)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 10 - Block 1 corridor art room 51 (73f62a9a-cf3b-4ba8-8ab5-8abf462113bd)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 11 - Block 1 corridor history  (43bda680-cd02-46bf-9ba0-52fc52a5c425)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 12 - Block 1 corridor millar hall (b3fd6c30-98d2-4631-8440-672278bdb4cd)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 13 - Block 1 corridor PE office (eeb0474f-50c9-4ca4-bcc8-2e4e0f530f8e)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pulse Meter (No Description) (656fd8bf-d3d2-4d18-a2e6-16543a422984)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Power Plug 1 (No Description) (424e0707-8bd7-4411-9853-efb96607471f)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Power Plug 4 (No Description) (81cd22eb-0fe3-4a35-a56d-28edf6056074)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Power Plug 5 (No Description) (c6ee2d07-fe9e-4f9c-8955-9201a94d09f1)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Power Plug 6 (No Description) (76fc5673-d058-4bc3-a8ad-4f664907b252)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Power Plug 7 (No Description) (56cb5bf0-6a2e-4542-826b-9fa83996eb6f)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Power Plug 8 (No Description) (a4189ec5-63b8-4c4c-abe7-b97fa958dca9)</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Section</th>
                                            <th>Values:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Building 2:</td>
                                            <td>Block 2</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Build:</td>
                                            <td>2nd August 1991</td>
                                        </tr>
                                        <tr>
                                            <td>Glazing Type:</td>
                                            <td>Double</td>
                                        </tr>
                                        <tr>
                                            <td>Insulated:</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>Kitchen:</td>
                                            <td>No</td>
                                        </tr>
                                        <tr>
                                            <td>Meters:</td>
                                            <td>
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th>Meter ID / Location:</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Sensor 15 - Block 2 careers suite (e65aa91e-8b2b-4e15-9f57-31aa8ba944fd)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 16 - Block 2 library corridor (ed013571-1de1-46b5-b83f-e1585b336639)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 17 - Block 2 corridor 6th form centre (cce6a884-b5e0-493e-93df-b88d7f2997e0)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 18 - Block 2 corridor music room (c8f60830-9e51-4231-bcaf-1c5176a9ab9d)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 19 - Block 2 corridor staff toilets (b8a5a1fa-6bd6-4e10-8a35-6a2e97f68a79)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 20 - Block 2 corridor lecture theatre (487eb409-2cf1-42ed-a97b-c96ba6c363c8)</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Section</th>
                                            <th>Values:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Building 3:</td>
                                            <td>Block 3</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Build:</td>
                                            <td>2nd August 1991</td>
                                        </tr>
                                        <tr>
                                            <td>Glazing Type:</td>
                                            <td>Double</td>
                                        </tr>
                                        <tr>
                                            <td>Insulated:</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>Kitchen:</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>Sensors:</td>
                                            <td>
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th>Meter ID / Location:</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Sensor 01 - Senior canteen (46f41379-9fd5-4608-8c51-d566a2cd31c2)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 02 - Senior canteen (19a6eb7f-466e-48d2-8a20-ca5d762bb043)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 03 - Junior canteen (fb452ad5-56d2-4bcd-a798-ed6d0092f8f4)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 04 - Junior canteen (200962bd-0a9b-40ad-8c9e-f9c1b3eb897c)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 05 - Kitchen fridge 3 (ac39a393-d27c-4f50-9eb9-12ca206c0341)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 06 - Kitchen fridge 1 (6030cd3d-d1c6-47c9-a65c-3d258b2846f9)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sensor 07 - Kitchen fridge 2 (0ef29d1a-5eaf-401e-8828-8966a619e964)</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Section</th>
                                            <th>Values:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Building 4:</td>
                                            <td>Block 4</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Build:</td>
                                            <td>2nd August 1991</td>
                                        </tr>
                                        <tr>
                                            <td>Glazing Type:</td>
                                            <td>Double</td>
                                        </tr>
                                        <tr>
                                            <td>Insulated:</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>Kitchen:</td>
                                            <td>No</td>
                                        </tr>
                                        <tr>
                                            <td>Sensors:</td>
                                            <td>None</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Section</th>
                                            <th>Values:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Building 5:</td>
                                            <td>Block 5</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Build:</td>
                                            <td>2nd August 1991</td>
                                        </tr>
                                        <tr>
                                            <td>Glazing Type:</td>
                                            <td>Double</td>
                                        </tr>
                                        <tr>
                                            <td>Insulated:</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>Kitchen:</td>
                                            <td>No</td>
                                        </tr>
                                        <tr>
                                            <td>Sensors:</td>
                                            <td>None</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </TabStripTab>
                            <TabStripTab title="Fleet">
                                <div>
                                    <Grid>
                                        <Cell small={12} medium={12} large={12}>
                                            <ExcelExport data={fleetData} ref={_export}>
                                                <DataGrid
                                                    style={{
                                                        height: "400px",
                                                    }}
                                                    data={orderBy(fleetData, sort)}
                                                    sortable={true}
                                                    sort={sort}
                                                    onSortChange={(e) => {
                                                        setSort(e.sort);
                                                    }}
                                                >
                                                    <Toolbar>
                                                        <button
                                                            title="Export Excel"
                                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                            onClick={excelExport}
                                                        >
                                                            Export to Excel
                                                        </button>
                                                    </Toolbar>
                                                    <Column field="Make" title="Make" />
                                                    <Column field="Model" title="Model" />
                                                    <Column field="Type" title="Type" />
                                                    <Column field="FuelType" title="Fuel Type" />
                                                    <Column field="Engine" title="Engine" />
                                                    <Column field="Reg" title="Registration" />
                                                    <Column field="BusId" title="Bus ID" />
                                                    <Column field="NoSeats" title="No. Seats" />
                                                    <Column field="NoStudents" title="No. Pupils" />
                                                    <Column field="Mileage" title="Mileage" />
                                                    <Column field="Year" title="Year" />
                                                    <Column field="Route" title="Route" />
                                                    <Column field="Miles" title="Miles" />
                                                    <Column field="Conversion" title="Conversion" />
                                                    <Column field="ExpectedCO2" title="ExpectedCO2" />
                                                    <Column field="Delivery" title="Delivery" />
                                                    <Column field="DeliveryMiles" title="DeliveryMiles" />
                                                    <Column field="DeliveryCO2" title="Delivery CO2" />
                                                    <Column field="Subcontractor" title="Subcontractor" />
                                                    <Column field="SubcontractorName" title="Subcontractor Name" />
                                                </DataGrid>
                                            </ExcelExport>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <div className="notesHeading">
                                                Expected daily kgCO2e is calculated using the Vehicle Miles per Route, and multiply out by the kgCO2e/unit conversion factor using the relevant yearly conversion factor for the vehicle type.  Expected kgCO2e = (miles * kgCO2e/unit).
                                            </div>
                                        </Cell>
                                    </Grid>
                                </div>
                            </TabStripTab>
                            <TabStripTab title="IT">
                                <div>
                                    <Grid>
                                        <Cell small={12} medium={6} large={6}>
                                            <Chart style={{height: 400}}>
                                                <ChartTitle text="Daily Percentage Breakdown for Electric from Source" />
                                                <ChartLegend position="bottom" />
                                                <ChartSeries>
                                                    <ChartSeriesItem
                                                        type="pie"
                                                        data={breakdownData}
                                                        field="share"
                                                        categoryField="kind"
                                                        labels={{
                                                            visible: true,
                                                            content: labelContent,
                                                        }}
                                                    />
                                                </ChartSeries>
                                                <ChartLegend visible={false} />
                                            </Chart>
                                        </Cell>
                                        <Cell small={12} medium={6} large={6}>
                                            <Chart style={{height: 400}}>
                                                <ChartTitle text="Daily Percentage Breakdown by Equipment Source" />
                                                <ChartLegend position="bottom" />
                                                <ChartSeries>
                                                    <ChartSeriesItem
                                                        type="pie"
                                                        data={equipData}
                                                        field="share"
                                                        categoryField="kind"
                                                        labels={{
                                                            visible: true,
                                                            content: labelContent,
                                                        }}
                                                    />
                                                </ChartSeries>
                                                <ChartLegend visible={false} />
                                            </Chart>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <ExcelExport data={itData} ref={_export}>
                                                <DataGrid
                                                    style={{
                                                        height: "400px",
                                                    }}
                                                    data={orderBy(itData, sort)}
                                                    sortable={true}
                                                    sort={sort}
                                                    onSortChange={(e) => {
                                                        setSort(e.sort);
                                                    }}
                                                >
                                                    <Toolbar>
                                                        <button
                                                            title="Export Excel"
                                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                            onClick={excelExport}
                                                        >
                                                            Export to Excel
                                                        </button>
                                                    </Toolbar>
                                                    <Column field="Category" title="Category" />
                                                    <Column field="SubCategory" title="Sub Category" />
                                                    <Column field="Make" title="Make" />
                                                    <Column field="Model" title="Model" />
                                                    <Column field="Year" title="Year" />
                                                    <Column field="Ownership" title="Ownership" />
                                                    <Column field="Count" title="Count" />
                                                    <Column field="Cost" title="Cost (£)" />
                                                    <Column field="RemainingTime" title="Remaining Time (Years)" />
                                                    <Column field="ConversionActive" title="Conversion Active" />
                                                    <Column field="ConversionSleep" title="Conversion Sleep" />
                                                    <Column field="ConversionOff" title="Conversion Off" />
                                                    <Column field="HoursBreakdown" title="Hours Breakdown" />
                                                    <Column field="DailyKwh" title="Daily KWh" />
                                                    <Column field="Conversion" title="Conversion (kgCO2e)" />
                                                    <Column field="ExpectedCo2Per" title="Expected kgCO2e Per Unit" />
                                                    <Column field="ExpectedCo2Total" title="Expected kgCO2e Total" />
                                                </DataGrid>
                                            </ExcelExport>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <div className="notesHeading">
                                                Expected daily kgCO2e is calculated using the expected Watt/usage per day and converting kWh, using the expected daily usage as 6 hours use in classes, 2 in sleep mode, and 16 hours switched off. Expected kWh/day is then multiplied out by the kgCO2e/unit conversion factor using the relevant yearly conversion factor for the energy type Electricity.  Watt to kWh = (daily Watt usage for type of use * Amp / 1000). Expected kgCO2e = (kWh * kgCO2e/unit).
                                            </div>
                                        </Cell>
                                    </Grid>
                                </div>
                            </TabStripTab>
                            <TabStripTab title="Kitchen">
                                <div>
                                    <Grid>
                                        <Cell small={12} medium={12} large={12}>
                                            <Chart style={{height: 400}}>
                                                <ChartTitle text="Daily Percentage Breakdown for Electric from Source" />
                                                <ChartLegend position="bottom" />
                                                <ChartSeries>
                                                    <ChartSeriesItem
                                                        type="pie"
                                                        data={breakdownData}
                                                        field="share"
                                                        categoryField="kind"
                                                        labels={{
                                                            visible: true,
                                                            content: labelContent,
                                                        }}
                                                    />
                                                </ChartSeries>
                                                <ChartLegend visible={false} />
                                            </Chart>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <DataGrid
                                                style={{
                                                    height: "400px",
                                                }}
                                                data={orderBy(kitchenData, sort)}
                                                sortable={true}
                                                sort={sort}
                                                onSortChange={(e) => {
                                                    setSort(e.sort);
                                                }}
                                            >
                                                <Toolbar>
                                                    <button
                                                        title="Export Excel"
                                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                        onClick={excelExport}
                                                    >
                                                        Export to Excel
                                                    </button>
                                                </Toolbar>
                                                <Column field="Equipment" title="Equipment" />
                                                <Column field="Make" title="Make" />
                                                <Column field="Model" title="Model" />
                                                <Column field="AverageKwh" title="Average KWh" />
                                                <Column field="DailyRunningTime" title="Daily Running Time (Hours)" />
                                                <Column field="DailyKwh" title="Daily KWh" />
                                                <Column field="Conversion" title="Conversion" />
                                                <Column field="DailyKgco2" title="Daily KgCO2" />
                                            </DataGrid>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <div className="notesHeading">
                                                Expected daily kgCO2e is calculated using the expected kWh per hour, using the expected daily usage as determine per equipment type, some requiring 24 hours run time, other shorter periods as per table. Expected kWh/unit is then multiplied out by the kgCO2e/unit conversion factor using the relevant yearly conversion factor for the energy type Electricity.  Expected kgCO2e = (kWh * kgCO2e/unit).
                                            </div>
                                        </Cell>
                                    </Grid>
                                </div>
                            </TabStripTab>
                            <TabStripTab title="GHG Emissions">
                                <div>
                                    <table>
                                        <thead>
                                        <tr>
                                            <td rowSpan="2">
                                                Emission Sources
                                            </td>
                                            <td colSpan="5">
                                                { baseLineYear } Baseline
                                            </td>
                                            <td colSpan="6">
                                                { currentYear } Reporting Period
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Amount</td>
                                            <td>KGCO2</td>
                                            <td>tCO2</td>
                                            <td>% of Total Yr CO2</td>
                                            <td>Per 100m<sup>2</sup></td>
                                            <td>Amount</td>
                                            <td>KGCO2</td>
                                            <td>tCO2</td>
                                            <td>% of Total Yr CO2</td>
                                            <td>Per 100m<sup>2</sup></td>
                                            <td>Difference on Baseline</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr className="scopeOneHeadRow">
                                            <td className="boldNoIndent">Scope 1</td>
                                            <td>-</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>-</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        {ghgEmissions.map((data,idx) =>
                                                <tr className="scopeOneContentRow">
                                                    <td className="boldWithIndent">{ idx }</td>
                                                    <td>327677</td>
                                                    <td>90307.781</td>
                                                    <td>90.307</td>
                                                    <td>14.13%</td>
                                                    <td>638.759</td>
                                                    <td>120328</td>
                                                    <td>4674.280</td>
                                                    <td>4.674</td>
                                                    <td>33.93%</td>
                                                    <td>33.061</td>
                                                    <td></td>
                                                </tr>
                                            )}

                                        <tr className="scopeTwoHeadRow">
                                            <td className="boldNoIndent">Scope 2</td>
                                            <td>-</td>
                                            <td>431598.153</td>
                                            <td>431.598</td>
                                            <td></td>
                                            <td>3052.752</td>
                                            <td>-</td>
                                            <td>1705.223</td>
                                            <td>1.705</td>
                                            <td></td>
                                            <td>0.558</td>
                                            <td></td>
                                        </tr>
                                        <tr className="scopeTwoLabelRow">
                                            <td className="boldNoIndent">Utilities</td>
                                            <td>-</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>-</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="scopeTwoContentRow">
                                            <td className="boldWithIndent">Electricity</td>
                                            <td>1248476</td>
                                            <td>431598.153</td>
                                            <td>431.598</td>
                                            <td>67.52%</td>
                                            <td>3052.752</td>
                                            <td>458554</td>
                                            <td>1705.223</td>
                                            <td>1.705</td>
                                            <td>12.38%</td>
                                            <td>0.558</td>
                                            <td></td>
                                        </tr>
                                        <tr className="scopeThreeHeadRow">
                                            <td className="boldNoIndent">Scope 3</td>
                                            <td>-</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>-</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className="scopeThreeLabelRow">
                                            <td className="boldNoIndent">Mobile Combustion</td>
                                            <td>-</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>-</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className="boldNoIndent">Totals</td>
                                            <td>-</td>
                                            <td>709588.089</td>
                                            <td>709.588</td>
                                            <td></td>
                                            <td>5018.375</td>
                                            <td>-</td>
                                            <td>71057.266</td>
                                            <td>71.057</td>
                                            <td></td>
                                            <td>431.866</td>
                                            <td></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </TabStripTab> */}
                            {energyData.map((item) =>
                                <TabStripTab title={item.kind}>
                                    <div>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>{item.kind}:</th>
                                                <th>Current Consumption:</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Usage</td>
                                                <td>{formatNumber(item.usage, 'n2')}</td>
                                            </tr>
                                            <tr>
                                                <td>kgCO2e</td>
                                                <td>{formatNumber(item.kgco2e , 'n2') }</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </TabStripTab>
                                )}

                        </TabStrip>
                    </Cell>
                </Grid>
            </div>
        </div>
    )
}

export default CompanyLanding;