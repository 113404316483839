import React, { useEffect, useState } from 'react';
import { MultiSelect, DropDownList } from "@progress/kendo-react-dropdowns"

const ComparativeFilter = ({
    year,
    normalizationMetric,
    emissionsCategory,
    years,
    normalizationMetrics,
    emissionsCategories,
    updateYear,
    updateNormalizationMetric,
    updateEmissionsCategory
}) => {
    return (
        <div>
            <MultiSelect
                data={years}
                onChange={updateYear}
                value={year}
                textField="label"
                dataValueField="value"
                placeholder="Year(s)"
                style={{ width: 'auto', margin: '0 0.5rem' }}
            />
            <DropDownList 
                data={normalizationMetrics}
                onChange={updateNormalizationMetric}
                value={normalizationMetric}
                textField="label"
                dataValueField="value"
                placeholder="Normalization Metric"
                style={{ width: '200px', margin: '0 0.5rem', height: '40px' }}
            />
            <DropDownList 
                data={emissionsCategories}
                onChange={updateEmissionsCategory}
                value={emissionsCategory}
                textField="label"
                dataValueField="value"
                placeholder="Emissions Category"
                style={{ width: '200px', margin: '0 0.5rem', height: '40px' }}
            />
        </div>
    )
}

export default ComparativeFilter