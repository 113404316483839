import {Grid, Cell} from "react-foundation";
import {Link, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import axios from "axios";
import $ from "jquery";
import React, {useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
import {GridToolbar as Toolbar} from "@progress/kendo-react-grid/dist/npm/GridToolbar";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid as DataGrid} from "@progress/kendo-react-grid/dist/npm/Grid";

// import * as React from "@types/react";

function WaterMeters() {
    const [siteName, setSiteName] = useState('');
    const [metersList, setMetersList] = React.useState([]);

    const params = useParams();
    const site = params['site'];

    const value = new Date();

    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            });
        }
        loadSite();

        const loadMeters = async () => {
            axiosApi.get(
                '/pillar/2/site-water-meters',
                {
                    params: {
                        site_id : site
                    }
                }).then(function (response) {

                 console.log(response.data);
                if (response['data']['data']['list'].length > 0) {
                    setMetersList(response['data']['data']['list']);
                }
            });
        }
        loadMeters();
    },[site]);
    return (
        <Grid>
            <Cell small={12} medium={2} large={2}>
                <div className="waterNavigation">
                    <h5>Inputs</h5>
                    <ul>
                        <li>
                            <Link to={'/water-meters/'+site} className="nav-link">Water Meters</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={'/water-meter-entries/'+site} className="nav-link">Water Meter Entries</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={'/input/water/'+site} className="nav-link">Water Meter Entry Upload</Link>
                        </li>
                    </ul>
                </div>
            </Cell>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion">
                                <Link to={'/dashboard/waterLanding/' + site}>&#11164; Back to Water Dashboard</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion padAlignRight">
                                <p>Start and End Dates:</p>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date(2016,8,1,0,0,0)} defaultShow={false} format={"dd-MM-yyyy"} name="startDate" id="startDate" />
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date()} defaultShow={false} format={"dd-MM-yyyy"} name="endDate" id="endDate" />
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Water Meters-{ siteName }</strong></div>
                        <Toolbar>
                            <Link to={'/input/add/water-meter/'+site}
                                title="Add Water Meter"
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            >
                                Add Water Meter 
                            </Link>
                        </Toolbar>
                        <Grid className="display">
                            <Cell small={12} medium={12} large={12}>
                                <DataGrid
                                    style={{
                                        height: "800px",
                                    }}
                                    data={metersList}
                                >
                                    <Column field="id" title="Id" width="150px" />
                                    <Column field="meter_name" title="Meter Name" />
                                    <Column field="metric_unit" title="Metric Unit" />
                                    <Column field="initial_reading" title="Initial Reading" />
                                </DataGrid>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default WaterMeters;