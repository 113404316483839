import {Link, useParams} from "react-router-dom";
import {Cell} from "react-foundation";
import * as React from "react";
const WasteNavbar = () => {
    const params = useParams();
    const siteid = params['site'];
    const id  = params['id'] ?? '';

    return (
        <Cell small={12} medium={2} large={2}>
            <div className="energyNavigation">
                <Cell small={12} medium={2} large={2}>
                    <div className="energyNavigation">
                        <h5>Pillar Reporting</h5>
                        <hr/>
                        <ul>
                            <li>
                                <Link to={'/sitelanding/' + siteid} className="nav-link">Environmental Management</Link>
                            </li>
                        </ul>
                        <hr/>
                    </div>
                </Cell>
            </div>
        </Cell>
    )
}

export default WasteNavbar