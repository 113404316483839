import React, { useEffect, useState } from 'react';

const ComparativeAccordion = ({title, content, icon, scopeOptions, kgco2e, isOpen, onToggle}) => {
    const headerStyle = {
        padding: '10px',
        backgroundColor: '#f5f5f5',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 'bold',
      };
    
      const contentStyle = {
        padding: '10px',
        backgroundColor: '#fafafa',
        borderTop: '1px solid #ddd',
      };
    
      const titleStyle = {
        display: 'flex',
        flexDirection: 'column',
      }

      const kgco2eStyle = {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'end',
      }

      useEffect(() => {
        console.log(scopeOptions);
      }, []);

    return (
        <div style={{ border: '1px solid #ddd', marginBottom: '10px' }}>
          <div style={headerStyle} onClick={onToggle}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{display: 'flex'}}>
                <img style={{width: '2.5rem', marginRight: '1rem'}} src={icon}/>
                    <div style={titleStyle}>
                        <span style={{fontSize: '18px', padding: '0rem 0', fontWeight: '600'}}>{title}</span>
                        <span style={{fontSize: '14px', padding: '0rem 0', fontWeight: '200', width: '95%'}}>{content}</span>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={kgco2eStyle}>
                  <span style={{fontSize: '18px', padding: '0rem 0', fontWeight: '600'}}>{kgco2e}</span>
                  <span style={{fontSize: '12px', padding: '0rem 0', fontWeight: '200'}}>KgCo2e</span>
                </div>
                {
                  isOpen ?
                  (<img style={{width: '2rem'}} src="https://api.iconify.design/material-symbols:keyboard-arrow-down.svg"/>) :
                  (<img style={{width: '2rem'}} src="https://api.iconify.design/material-symbols:keyboard-arrow-up.svg"/>)
                  }
                </div>
            </div>
          </div>
          {
            isOpen && (
                <div style={contentStyle}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {scopeOptions.map((scope) => {
                        return (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <span>{scope.name}</span>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <span>{scope.kgco2e}</span>
                                <img style={{width: '2rem', marginLeft: '1rem'}} onClick={scope.handleTableUpdate} src="https://api.iconify.design/material-symbols:table-chart-view.svg"/>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>
            )
          }
        </div>
      );
}

export default ComparativeAccordion;