import * as React from "react";
import {Grid, Cell} from "react-foundation";
import { Grid as DataGrid, GridColumn as Column, GridToolbar as Toolbar } from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

import "hammerjs";
import {Link, useNavigate, useParams, useLocation} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import { orderBy } from "@progress/kendo-data-query";
import $ from "jquery";
import {useCallback, useEffect, useState} from "react";
import axiosApi from "../api/axiosInstance";
import EnegryNavbar from "../components/EnergyNavbar";
import {FormatEnergyEntries} from "./Energy/EnergyHelper";
import EnergyChart from "./Energy/EnergyChart";
import { formatNumber } from '@progress/kendo-intl';
import {parseNumber} from "./form/format-helper";

import {
    Chart,
    ChartBreadcrumb,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartXAxis,
    ChartXAxisItem,
    ChartYAxis,
    ChartYAxisItem,
} from "@progress/kendo-react-charts";
import EnergyDrillDownChart from "./Energy/EnergyDrillDownChart";




function EnergyLanding() {

    const navigate = useNavigate();
    const location = useLocation();

    const [energyName, setEnergyName] = useState('');
    const [energyMeasure, setEnergyMeasure] = useState('');

    const [energyDataChart, setEnergyDataChart] = useState([]);
    const [energyDataTable, setEnergyDataTable] = useState([]);
    const [energyDataSplitChart, setEnergyDataSplitChart] = useState([]);
    const [totalSummaryByCode, setTotalSummaryByCode] = useState([]);
    const [totalEnergySummary , setTotalEnergySummary] = useState([]);
    const [totalMqttSummary , setTotalMqttSummary] = useState([]);
    const [temperatureData , setTemperatureData] = useState([]);

    const[isMqtt, setIsMqtt] = useState(false);

    const [energyMqttDataChart, setEnergyMqttDataChart] = useState([]);
    const [energyMqttDataTable, setEnergyMqttDataTable] = useState([]);

    const [siteData, setSiteData] = useState(['']);

    const params = useParams();
    const id = params['id'];
    const site = params['site'];


    const [searchFormState, setSearchFormState] = React.useState({
        fromDate: new Date(2016,8,1),
        toDate: new Date(),
    });
    const [startDate, setStartDate] = useState(searchFormState.fromDate);
    const [endDate, setEndDate] = useState(searchFormState.toDate);
    const handleChange = (event) => {
        setSearchFormState({
            ...searchFormState,
            [event.target.name]: event.target.value,
        });
    };
    const handleDateSearch = (event) => {
        event.preventDefault();
        setStartDate(searchFormState.fromDate);
        setEndDate(searchFormState.toDate);
    };


    const _export = React.useRef(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const initialSort = [
        {
            field: "Date",
            dir: "desc",
        },
    ];

    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const [sort, setSort] = React.useState(initialSort);

    const [sensorAvg, setSensorAvg] = useState(0);
    const [sensorAvgM2, setSensorAvgM2] = useState(0);

    const useSensorByDayData = (date) => {
        const [data, setData] = React.useState([]);
        const sensors_data_by_day = []
        React.useEffect(() => {
            const loadDayData = async () => {
                axiosApi.get(
                    'pillar/2/sensor-energy-entries/meter-summary',
                    {
                        params: {
                            'added_on': date,
                            'site_id' : site,
                            'pillar_two_energy_type_id': id,
                        }
                    }
                ).then(function (response) {
                    if (response['data']['data']) {
                        let by_meter = response['data']['data']['summary']['by_energy_meter'];

                        for (let i = 0; i < by_meter.length; i++) {

                            let list = by_meter[i]['list'];
                            let name = '';
                            let chart_data =[];
                            for (let i = 0; i < list.length; i++) {

                                let entry = list[i];
                                name = entry['boiler_name'] ? entry['boiler_name'] : entry['meter_name'];
                                let ontime = parseInt(entry['on_time'].slice(0,2))+'.'+entry['on_time'].slice(3,5);
                                chart_data.push({
                                    usage: parseNumber(entry['amount']),
                                    time: ontime,
                                    duration: entry['duration'] != '00:00:00' ? entry['duration'].slice(0,5) : ''
                                });
                                if(entry['off_time']) {
                                    chart_data.push({
                                        usage: 0,
                                        time: parseInt(entry['off_time'].slice(0,2))
                                    });
                                }
                            }
                            sensors_data_by_day.push({
                                name: name,
                                data: chart_data
                            });
                        }
                        setTimeout(() => setData(sensors_data_by_day), simulatedDelay);
                    }
                });
            }
            loadDayData();

        }, [date]);

        return data;
    };

    //Drilldown chart
    const simulatedDelay = 500;
    const DrilldownByDaySeries = (props) => {

        return (
            <>
        <ChartXAxis>
            <ChartXAxisItem
                title={{
                    text: "Time",
                }}
                type="numeric"
                max="23"
                majorUnit={1}
            />
        </ChartXAxis>
        <ChartYAxis>
            <ChartYAxisItem
                title={{
                    text: "Usage in" + energyMeasure,
                }}
                labels={{
                    format: "#.00",
                }}
            />
        </ChartYAxis>
        <ChartTooltip format="at {0}, {1}" />
        <ChartSeries>
            {useSensorByDayData(props.drilldownValue)?.map((item, idx) => (
                <ChartSeriesItem
                    key={idx}
                    type="scatterLine"
                    tooltip={{visible: true}}
                    data={item.data}
                    name={item.name}
                    xField="time"
                    yField="usage"
                    noteTextField="duration"
                    {...props}
                />
            ))}
        </ChartSeries>
            </> );
    };

    const [drilldownState, setDrilldownState] = React.useState();
    const handleDrilldown = (e) => {
        setDrilldownState(e.nextState);
    };

    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteData(response['data']['data']);
                }
            });
        }
        loadSite();
    },[site]);

    useEffect(() => {
        const loadEnergyType = async () => {
            axiosApi.get(
                '/pillar/2/energy-types/' + id,
            ).then(function (response) {
                if (response['data']['data']) {
                    setEnergyName(response['data']['data']['name']);
                    setEnergyMeasure(response['data']['data']['measurement']['abbr']);
                }
            });
        }
        loadEnergyType();
    },[id]);

    useEffect(() => {

        const loadEnergyUsageGraph = async () => {

            let url = '/pillar/2/site-energy-meter-entries/report-summary';
            let params = {
                site_id: site,
                start_date: startDate,
                end_date: endDate,
                energy_type_id: id
            };


            //get energy data for upload Data
            params['source_type_id'] =1;
            params['by_meter'] = 1;
            axiosApi.get(
                url,
                {
                    params:  params
                }
            ).then(function (response) {
                let data = response['data']['data'];
                /*let entries = [];
                entries = data['data']['list']; */

                let result = FormatEnergyEntries(data, true);
                //console.log(result);
                setEnergyDataChart(result['energy_chart_data']);
                setEnergyDataTable(result['energy_table_data']);
                setEnergyDataSplitChart(result['energy_chart_codes_data']);

                setTotalEnergySummary(result['total_summary']);
                setTotalSummaryByCode(result['total_summary_by_code']);

            });


            //Check for mqtt data


            params['source_type_id'] = '2';
            params['by_meter'] = false;
            axiosApi.get(
                url,
                {
                    params:  params
                }
            ).then(function (response) {
                let data = response['data']['data'];
                let result = FormatEnergyEntries(data);
                if(data['list'].length > 0) {
                    setIsMqtt(true);
                }
                setEnergyMqttDataChart(result['energy_chart_api_data']);
                setEnergyMqttDataTable(result['energy_table_data']);

                //console.log(result);
                setTotalMqttSummary(result['total_summary'])
                setTemperatureData(result['temperature_series']);

                let avg = result['energy_chart_api_data']['0'] ? result['energy_chart_api_data']['0']['avg']??0 : 0;
                setSensorAvg(avg);
                //setSensorAvgM2(siteData.floor_area > 0 ? (avg/siteData.floor_area)*100 : 0 );

            });

        }
        loadEnergyUsageGraph();
    },[id,startDate,endDate,site]);


    return (
        <Grid>
            <EnegryNavbar/>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={12} large={2}>
                            <div className="padRegion">
                                <Link to={'/dashboard/energy/' + site} className="linkBack">&#11164; Back to Energy Dashboard</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={12} large={4}></Cell>

                        <Cell small={12} medium={12} large={6}>
                            <form className="k-form" onSubmit={handleDateSearch}>
                                <div className="date-filter-form">
                                    <div className="padAlignRight">
                                        <p>Start and End Dates:</p>
                                    </div>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={startDate}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="fromDate"
                                            id="startDate" max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={new Date()}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="toDate"
                                            id="endDate"
                                            min={startDate} max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>

                                <input
                                    type="submit"
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                    value="Search"
                                />
                                </div>
                            </form>

                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Energy Summary -{ siteData.name }-{ energyName }</strong></div>

                            <TabStrip selected={selected} onSelect={handleSelect}>
                                <TabStripTab title={ energyName }>
                                    <Cell small={12} medium={12} large={12}>
                                        <Cell small={12} medium={12} large={12}>
                                            <EnergyChart
                                                key = {Math.random()}
                                                chartData={energyDataChart}
                                                title={energyName + " Usage By Month"}
                                                xaxisTitle = {energyMeasure}
                                                yaxisTitle = {energyName + " Actual"}
                                                floorArea = {siteData.floor_area}
                                                type = "column"
                                                color = "green"
                                            />
                                            <div className="energyEstimate">
                                                <span></span>
                                                {energyName + " (Estimated)"}
                                            </div>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <Grid className="display">
                                                <Cell small={12} medium={3} large={3}>
                                                    <div className="quickSelectTab">
                                                        <h4>Usage</h4>
                                                        <p>Total: {totalEnergySummary['amount'] ?? 0} {energyMeasure}</p>
                                                        <p>Kwh: {totalEnergySummary['kwh'] ?? 0}</p>
                                                    </div>
                                                </Cell>
                                                <Cell small={12} medium={3} large={3}>
                                                    <div className="quickSelectTab">
                                                        <h4>kgCO2</h4>
                                                        <p>Total: {totalEnergySummary['kgco2'] ?? 0}</p>
                                                    </div>
                                                </Cell>
                                                <Cell small={12} medium={3} large={3}>
                                                    <div className="quickSelectTab">
                                                        <h4>kgCO2e</h4>
                                                        <p>Total: {totalEnergySummary['kgco2e'] ?? 0}</p>
                                                    </div>
                                                </Cell>
                                                <Cell small={12} medium={3} large={3}>
                                                    <div className="quickSelectTab">
                                                        <h4>tCO2e</h4>
                                                        <p>Total: {totalEnergySummary['tco2e'] ?? 0}</p>
                                                    </div>
                                                </Cell>
                                            </Grid>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                                <DataGrid
                                                    style={{
                                                        height: "400px",
                                                    }}
                                                    data={orderBy(energyDataTable, sort)}
                                                    sortable={true}
                                                    sort={sort}
                                                    onSortChange={(e) => {
                                                        setSort(e.sort);
                                                    }}
                                                >
                                                    <Toolbar>
                                                        <Link to={'/energy-meter-entries/' + site + '/' + id}  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                                                            See All Inputs
                                                        </Link>
                                                    </Toolbar>
                                                    <Column field="Meter" title="Meter" width="180" />
                                                    <Column field="EnergyType" title="Energy Type" />
                                                    <Column field="Date" title="Date" />
                                                    <Column field="Amount" title="Amount" format="{0:n2}"/>
                                                    <Column field="kgCO2eTotal" title="KgCO2e Total" format="{0:n2}"/>
                                                    <Column field="tCO2eTotal" title="tCO2e Total" format="{0:n4}"/>
                                                    <Column field="Kwh" title="kWh" format="{0:n2}"/>
                                                    <Column field="InputType" title="InputType"/>
                                                    <Column field="BillType" title="BillType"/>
                                                </DataGrid>
                                        </Cell>
                                    </Cell>
                                </TabStripTab>

                                <TabStripTab title={ energyName + " Split"}>
                                    <Cell small={12} medium={12} large={12}>
                                        <Cell small={12} medium={12} large={12}>
                                            <EnergyChart
                                                key = {Math.random()}
                                                chartData={energyDataSplitChart}
                                                title={energyName + " Usage By Month and By Location"}
                                                xaxisTitle = {energyMeasure}
                                                type = "line"
                                            />
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <Grid className="display">
                                                {totalSummaryByCode?.map((item,idx) => (
                                                    <Cell small={12} medium={3} large={3}>
                                                        <div className="quickSelectTab">
                                                            <h4>{item.name} - Total</h4>
                                                            <p>Usage: {item.amount} {energyMeasure}</p>
                                                            <p>Kwh: {item.kwh} </p>
                                                            <p>KgCO2: {item.kgco2} </p>
                                                            <p>KgCO2e: {item.kgco2e}</p>
                                                            <p>TCO2e: {item.tco2e}</p>
                                                        </div>
                                                    </Cell>
                                                ))}

                                            </Grid>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                                <DataGrid
                                                    style={{
                                                        height: "400px",
                                                    }}
                                                    data={orderBy(energyDataTable, sort)}
                                                    sortable={true}
                                                    sort={sort}
                                                    onSortChange={(e) => {
                                                        setSort(e.sort);
                                                    }}
                                                >
                                                    <Toolbar>
                                                        <Link to={'/energy-meter-entries/' + site + '/' + id}  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                                                            See All Inputs
                                                        </Link>
                                                    </Toolbar>
                                                    <Column field="Meter" title="Meter" width="180" />
                                                    <Column field="EnergyType" title="Energy Type" />
                                                    <Column field="Date" title="Date" />
                                                    <Column field="Amount" title="Amount" format="{0:n2}"/>
                                                    <Column field="kgCO2eTotal" title="KgCO2e Total" format="{0:n2}"/>
                                                    <Column field="tCO2eTotal" title="tCO2e Total" format="{0:n4}"/>
                                                    <Column field="Kwh" title="kWh" format="{0:n2}"/>
                                                    <Column field="Kitchen" title="Kitchen" format="{0:n2}"/>
                                                    <Column field="School" title="School" format="{0:n2}"/>
                                                    <Column field="General" title="General" format="{0:n}"/>
                                                    <Column field="InputType" title="InputType"/>
                                                    <Column field="BillType" title="BillType"/>
                                                </DataGrid>
                                        </Cell>
                                    </Cell>
                                </TabStripTab>

                                {(isMqtt === true) ? (
                                <TabStripTab title="Sensor Data">
                                    <Cell small={12} medium={12} large={12}>
                                        <div className="dashContainerHeader" >Avg Usage per Day: {formatNumber(sensorAvg, "n") + ' ' +energyMeasure}
                                            {siteData.floor_area > 0 ? <div>   Avg Usage per Day per 100M2: {formatNumber((sensorAvg/siteData.floor_area)*100, "n") + ' ' +energyMeasure} </div> : ''}
                                        </div>
                                        <ChartBreadcrumb
                                            drilldownState={drilldownState}
                                            onDrilldownStateChange={handleDrilldown}
                                            rootItem={{
                                                text: "ChartByDay"
                                            }}
                                        />
                                        <Chart
                                            drilldownState={drilldownState}
                                            onDrilldown={handleDrilldown}
                                            pannable={{
                                                lock: "y",
                                            }}
                                            zoomable={{
                                                mousewheel: {
                                                    lock: "y",
                                                },
                                                selection: {
                                                    lock: "y",
                                                },
                                            }}
                                        >
                                            <ChartTitle text={energyName + "Usage From Sensor By Day"} />
                                            <ChartLegend position="bottom" />

                                            <ChartCategoryAxis>
                                                <ChartCategoryAxisItem
                                                    labels={{
                                                        font: "10px Arial,Helvetica,sans-serif",
                                                        rotation: {
                                                            angle: 270
                                                        }
                                                    }}
                                                />
                                            </ChartCategoryAxis>
                                            <ChartTooltip format="{0}" />

                                            <ChartSeries>
                                                {energyMqttDataChart?.map((item, idx) => (
                                                    <ChartSeriesItem
                                                        key={idx}
                                                        type='column'
                                                        tooltip={{ visible: true }}
                                                        data={item.data}
                                                        name={energyName + ' Actual'}
                                                        field="value" categoryField="category"
                                                        drilldownField="category"
                                                        drilldownSeriesFactory={DrilldownByDaySeries}
                                                        color = "green"
                                                        axis = "energy"
                                                    />
                                                ))}
                                                {(id==2 || id==15) ?
                                                <ChartSeriesItem
                                                    key="temp-outdoor-35"
                                                    type='line'
                                                    tooltip={{ visible: true }}
                                                    data={temperatureData['chart']['1'].data ?? []}
                                                    name = {temperatureData['chart']['1'].name ?? ''}
                                                    field="value" categoryField="category"
                                                    color="red"
                                                    axis ="temp"
                                                /> : ''}
                                            </ChartSeries>

                                            <ChartValueAxis>
                                                <ChartValueAxisItem
                                                    title={{
                                                        text: energyMeasure,
                                                        position: "top"
                                                    }}
                                                    labels={{
                                                        visible: true,
                                                    }}
                                                    key={1}
                                                    min={0}
                                                    name = "energy"
                                                />
                                                {(id==2 || id==15) ?
                                                <ChartValueAxisItem
                                                    title={{
                                                        text: "temperature [&deg;C]",
                                                        position: "top"
                                                    }}
                                                    labels={{
                                                        visible: true,
                                                    }}
                                                    key={2}
                                                    min={0}
                                                    max={40}
                                                    name = "temp"
                                                /> : ''}

                                            </ChartValueAxis>

                                        </Chart>

                                        <div className="energyEstimate">
                                            <span className="yellow"></span>
                                            {energyName + " (Weekend)"}
                                        </div>
                                    </Cell>
                                    <Cell small={12} medium={12} large={12}>
                                        <Grid className="display">
                                            <Cell small={12} medium={3} large={3}>
                                                <div className="quickSelectTab">
                                                    <h4>Usage</h4>
                                                    <p>Total: {totalMqttSummary['amount'] ?? 0} {energyMeasure}</p>
                                                    <p>Kwh: {totalMqttSummary['kwh'] ?? 0}</p>
                                                </div>
                                            </Cell>
                                            <Cell small={12} medium={3} large={3}>
                                                <div className="quickSelectTab">
                                                    <h4>kgCO2</h4>
                                                    <p>Total: {totalMqttSummary['kgco2'] ?? 0}</p>
                                                </div>
                                            </Cell>
                                            <Cell small={12} medium={3} large={3}>
                                                <div className="quickSelectTab">
                                                    <h4>kgCO2e</h4>
                                                    <p>Total: {totalMqttSummary['kgco2e'] ?? 0}</p>
                                                </div>
                                            </Cell>
                                            <Cell small={12} medium={3} large={3}>
                                                <div className="quickSelectTab">
                                                    <h4>tCO2e</h4>
                                                    <p>Total: {totalMqttSummary['tco2e'] ?? 0}</p>
                                                </div>
                                            </Cell>
                                        </Grid>
                                    </Cell>
                                    <Cell small={12} medium={12} large={12}>
                                            <DataGrid
                                                style={{
                                                    height: "400px",
                                                }}
                                                data={orderBy(energyMqttDataTable, sort)}
                                                sortable={true}
                                                sort={sort}
                                                onSortChange={(e) => {
                                                    setSort(e.sort);
                                                }}
                                            >
                                                <Toolbar>
                                                    <Link to={'/energy-meter-entries/' + site + '/' + id}  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                                                        See All Inputs
                                                    </Link>
                                                </Toolbar>
                                                <Column field="Meter" title="Meter" width="180" />
                                                <Column field="EnergyType" title="Energy Type" />
                                                <Column field="Date" title="Date" />
                                                <Column field="Amount" title="Amount" format="{0:n2}"/>
                                                <Column field="kgCO2eTotal" title="KgCO2e Total" format="{0:n2}"/>
                                                <Column field="tCO2eTotal" title="tCO2e Total" format="{0:n4}"/>
                                                <Column field="Kwh" title="kWh" format="{0:n2}"/>
                                                <Column field="InputType" title="InputType"/>
                                                <Column field="BillType" title="BillType"/>
                                            </DataGrid>
                                    </Cell>
                                </TabStripTab>
                                ) : ''}
                                    {(isMqtt === true && (id==2 || id==15)) ? (
                                <TabStripTab title="U-Value">
                                    <div className="chart-tab-width">
                                    <Cell small={12} medium={12} large={12}>
                                        <div className="dashContainerHeader">U-Value: {temperatureData['u-value']??0}
                                            <div> Heat Loss per M2 : {temperatureData['heat-loss']??0}</div>
                                            {siteData.floor_area > 0 ? <div> Overall HeatLoss for School: {temperatureData['heat-loss'] ? temperatureData['heat-loss']*siteData.floor_area : 0 } </div> : ''}
                                        </div>
                                        <Cell small={12} medium={12} large={12}>
                                            <EnergyChart
                                                key = {Math.random()}
                                                chartData={temperatureData['chart'] ?? []}
                                                title="Indoor and Outdoor Temperature by Day"
                                                xaxisTitle = "[&deg;C]"
                                                type = "line"
                                            />
                                        </Cell>
                                    </Cell>
                                    </div>
                                </TabStripTab>

                                ) : ''}
                            </TabStrip>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default EnergyLanding;