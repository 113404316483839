import { Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartTitle, ChartLegend } from '@progress/kendo-react-charts';
import { Card, CardHeader, CardTitle, CardBody, CardActions, CardImage, CardSubtitle, Avatar } from '@progress/kendo-react-layout';

const ComparativePie = ({reportType, data}) => {
    const labelContent = (e) => e.name;
    const categories = [2002, 2003, 2004];
    return (
        <div>
            <Card>
                <Chart style={{
                    height: 350
                }}>
                    <ChartTitle text="" />
                    <ChartLegend position="bottom" orientation="horizontal" />
                    <ChartSeries>
                        {/* {
                            reportType === 'line' ?
                            data.map((series) => {
                            <ChartSeriesItem 
                            type={reportType} 
                            tooltip={{visible: true}} 
                            name={series.name}
                            data={series.data} 
                            field="share"
                            />
                            }) :
                            <ChartSeriesItem 
                            type={reportType} 
                            tooltip={{visible: true}} 
                            data={data} 
                            categoryField="name" 
                            field="share"
                            content={labelContent} />
                        } */}
                        <ChartSeriesItem 
                        type={reportType} 
                        tooltip={{visible: true}} 
                        data={data} 
                        categoryField="name" 
                        field="share"
                        content={labelContent} 
                        />
                    </ChartSeries>
                </Chart>
            </Card>

            {/* <Card>
                <Chart style={{
                    height: 350
                }}>
                    <ChartTitle text="" />
                    <ChartLegend position="bottom" orientation="horizontal" />
                    <ChartCategoryAxis>
                            <ChartCategoryAxisItem categories={categories} startAngle={45} />
                        </ChartCategoryAxis>
                    <ChartSeries>
                        {
                            lineData.map((series) => {
                                <ChartSeriesItem 
                                type="line" 
                                tooltip={{visible: true}} 
                                name={series.name}
                                data={series.data} 
                                field="share"
                                />
                            })
                        }
                    </ChartSeries>
                </Chart>
            </Card> */}
        </div>
    )
}

export default ComparativePie