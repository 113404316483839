import React, { useState } from 'react';
import ComparativeAccordion from './ComparativeAccordion';
import ComparativeTable from './comparativeTable';
import {Grid, Cell} from "react-foundation";
import ComparativeFilter from './comparativeFilter';
import ComparativePie from './comparativePie';
import { scope1Data, companyOwnedControlledFacilities } from './tableData';
import { pieData, pieData2, lineData } from './chartData';

// AccordionItem Component
const EmissionsByScope = ({}) => {

    //state variables
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
    const [selectedYears, setSelectedYears] = useState([{ value: '2019', label: '2019' }]);
    const [normalizationMetric, setNormalizationMetric] = useState({ value: 'number_of_staff', label: 'Number of Staff' });
    const [emissionsCategory, setEmissionsCategory] = useState({ value: 'all', label: 'All' });
    const [selectedTableYears, setSelectedTableYears] = useState([]);
    const [baselineYear, setBaselineYear] = useState('2019');
    const [tableData, setTableData] = useState(scope1Data);
    const [reportType, setReportType] = useState('donut');
    const [reportData, setReportData] = useState(pieData);

    //static data
    const accordionItems = [
        {
          title: 'Scope 1',
          content: 'Emissions from sources that your organization owns or controls directly',
          icon: 'https://api.iconify.design/material-symbols:counter-1-outline.svg',
          kgco2e: '87,971',
          scopeOptions: [
            {name: 'Company Owned/Controlled Facilities', kgco2e: '19,906', handleTableUpdate: () => handleTableUpdate(companyOwnedControlledFacilities, 'donut', pieData2)},
            {name: 'Company Owned/Controlled Vehicles', kgco2e: '19,906'},
            {name: 'Fugitive Emissions', kgco2e: '19,906'}
          ]
        },
        {
          title: 'Scope 2',
          content: 'Emissions that your company causes indirectly when the energy it purchases and uses is produced',
          kgco2e: '218,102',
          icon: 'https://api.iconify.design/material-symbols:counter-2-outline.svg',
          scopeOptions: [
            {name: 'Purchases Electricity', kgco2e: '216,115'},
            {name: 'Electricity for EV', kgco2e: '1,987'}
          ]
        },
        {
          title: 'Scope 3',
          content: 'Emissions your company is indirectly responsible for, up and down the value chain',
          icon: 'https://api.iconify.design/material-symbols:counter-3-outline.svg',
          kgco2e: '166,353',
          scopeOptions: [
            {name: 'Purchased goods and services', kgco2e: '14,420'},
            {name: 'Capital Goods', kgco2e: '1,419'},
            {name: 'Other Energy Related Activities', kgco2e: '11,839'}
          ]
        }
    ];

    const tableHeadings = [
        {field: 'type', title: 'Type'},
        {field: 'unit', title: 'Unit'},
        {field: 'total', title: 'Total'},
        {field: 'emission_type', title: 'Emission Type'},
        {field: 'tco2e', title: 'tCO2e'},
        {field: 'percent_total_year_co2', title: '% of Total Year CO2'},
        {field: 'per_FTE', title: 'Per FTE'}
    ]

    const years = [
        { value: '2019', label: '2019' },
        { value: '2020', label: '2020' },
        { value: '2021', label: '2021' },
        { value: '2022', label: '2022' },
        { value: '2023', label: '2023' },
        { value: '2024', label: '2024' },
        { value: '2025', label: '2025' },
    ];

    const normalizationMetrics = [
        { value: 'number_of_staff', label: 'Number of Staff' },
        { value: 'per_100m2', label: 'Per 100m2' },
        { value: 'per_1m', label: 'Per £1m' },
        { value: 'per_100k', label: 'Per £100,000' },
    ];

    const emissionsCategories = [
        { value: 'all', label: 'All' },
        { value: 'energy', label: 'Energy (Build Environment)' },
        { value: 'transport', label: 'Transport' },
        { value: 'waste', label: 'Waste' },
        { value: 'land_use', label: 'Land Use' },
    ];
    
    //events
    const updateNormalizationMetric = (event) => {
        setNormalizationMetric(event.value);
        console.log('Selected Normalization Metric:', event.value);
    };

    const updateEmissionsCategory = (event) => {
        setEmissionsCategory(event.value);
        console.log('Selected Emissions Category:', event.value);
    };

    const updateYear = (event) => {
        const selected = event.target.value;
      
        const updatedYears = [
          ...selected,
          { value: baselineYear, label: baselineYear },
        ];
      
        const uniqueYears = Array.from(
          new Set(updatedYears.map((year) => year.value))
        ).map((value) => years.find((year) => year.value === value));
      
        setSelectedYears(uniqueYears);
      };

    //functions

    const handleToggleAccordion = (index, relatedTableData) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
        setTableData(relatedTableData)
      };

    const filteredTableYears = tableData.filter((table) =>
        selectedYears.some((year) => year.value === table.year.toString())
      );

    const handleTableUpdate = (relatedTableData, reportType, reportData) => {
        setTableData(relatedTableData)
        setReportType(reportType)
        setReportData(reportData)
        console.log('tracking')
    }

    const handleReportUpdate = (reportType, data) => {

    }

    //render
    return (
        <div style={{margin: '2rem 2rem 0 2rem', padding: '1rem'}}>
            <ComparativeFilter
                year={selectedYears}
                normalizationMetric={normalizationMetric}
                emissionsCategory={emissionsCategory}
                years={years}
                normalizationMetrics={normalizationMetrics}
                emissionsCategories={emissionsCategories}
                updateYear={updateYear}
                updateNormalizationMetric={updateNormalizationMetric}
                updateEmissionsCategory={updateEmissionsCategory}
            />
            <Grid className="display" style={{backgroundColor: '#ffffff', padding: '1rem', margin: '2rem 0', borderRadius: '3px'}}>
                <Cell small={12} large={6} style={{padding: '0 0.5rem'}}>
                    {accordionItems.map((item, index) => (
                        <ComparativeAccordion
                            key={index}
                            title={item.title}
                            content={item.content}
                            kgco2e={item.kgco2e}
                            icon={item.icon}
                            scopeOptions={item.scopeOptions}
                            isOpen={openAccordionIndex === index}
                            onToggle={() => handleToggleAccordion(index, scope1Data)}
                        />
                    ))}
                </Cell>
                <Cell small={12} large={6} style={{padding: '0 0.5rem'}}>
                    {
                        filteredTableYears.map((table) => {
                            return (<ComparativeTable key={table.year} year={table.year} data={table.data} cols={tableHeadings}/>)
                        })
                    }
                    <ComparativePie reportType={reportType} data={reportData}/>
                </Cell>
            </Grid>
        </div>
    );
};



export default EmissionsByScope;