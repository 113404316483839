import {Grid, Cell} from "react-foundation";
import {Link, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import $ from "jquery";
import React, {useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
import {GridToolbar as Toolbar} from "@progress/kendo-react-grid/dist/npm/GridToolbar";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid as DataGrid} from "@progress/kendo-react-grid/dist/npm/Grid";
import {filterBy, orderBy} from "@progress/kendo-data-query";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {FormatWaterEntries} from "../Water/WaterHelper";

// import * as React from "@types/react";

function WaterMeters() {
    const [siteName, setSiteName] = useState('');
    const [waterName, setWaterName] = useState('');
    const [meterEntryList, setMeterEntryList] = React.useState([]);

    const params = useParams();
    const site = params['site'];
    const id = params['id'];

    const value = new Date();

    const _export = React.useRef(null);
    const [filter, setFilter] = React.useState();
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };
    const initialSort = [
        {
            field: "id",
            dir: "desc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);

    const sortChange = (event) => {
        setMeterEntryList(getMeterEntries(event.sort));
        setSort(event.sort);
    };
    const getMeterEntries = (sort) => {
        return orderBy(meterEntryList, sort);
    };

    const [searchFormState, setSearchFormState] = React.useState({
        fromDate: new Date(2016,8,1),
        toDate: new Date(),
    });
    const [startDate, setStartDate] = useState(searchFormState.fromDate);
    const [endDate, setEndDate] = useState(searchFormState.toDate);
    const handleChange = (event) => {
        setSearchFormState({
            ...searchFormState,
            [event.target.name]: event.target.value,
        });
    };
    const handleDateSearch = (event) => {
        event.preventDefault();
        setStartDate(searchFormState.fromDate);
        setEndDate(searchFormState.toDate);
    };


    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            });
        }
        loadSite();

        const loadMeterEntries = async () => {
            // Fetch meter entries
            const meterEntriesResponse = await axiosApi.get('/pillar/2/site-water-meter-entries', {
                params: {
                    site_id: site,
                    start_date: startDate,
                    end_date: endDate
                }
            });
        
            // Extract meter entries data
            const meterEntriesData = meterEntriesResponse.data.data.list;
        
            if (meterEntriesData.length > 0) {
                // Extract unique meter IDs
                const uniqueMeterIds = [...new Set(meterEntriesData.map(entry => entry.meter_id))];
        
                // Fetch meter names for each unique meter ID
                const meterNamesPromises = uniqueMeterIds.map(async meterId => {
                    const meterResponse = await axiosApi.get(`/pillar/2/site-water-meters/${meterId}`);
                    return meterResponse.data.data.meter_name;
                });
        
                // Resolve all promises
                const meterNames = await Promise.all(meterNamesPromises);
        
                // Replace meter_ids with meter_names in meter entries data
                const meterEntriesWithNames = meterEntriesData.map(entry => ({
                    ...entry,
                    meter_name: meterNames[uniqueMeterIds.indexOf(entry.meter_id)]
                }));
        
                // Update state with meter entries including meter names
                setMeterEntryList(meterEntriesWithNames);
            }
        };
        loadMeterEntries();
        
    },[site,id,startDate,endDate]);
    return (
        <Grid>
            <Cell small={12} medium={2} large={2}>
                <div className="waterNavigation">
                    <h5>Inputs</h5>
                    <ul>
                        <li>
                            <Link to={'/water-meters/'+site} className="nav-link">Water Meters</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={'/water-meter-entries/'+ site + (id ? '/'+id : '')} className="nav-link">Water Meter Entries</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={'/input/water/'+site} className="nav-link">Water Meter Entry Upload</Link>
                        </li>
                    </ul>
                </div>
            </Cell>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={12} large={2}>
                            <div className="padRegion">
                                <Link to={'/dashboard/waterLanding/' + site}>&#11164; Back to Water Dashboard</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={12} large={4}></Cell>

                        <Cell small={12} medium={12} large={6}>
                            <form className="k-form" onSubmit={handleDateSearch}>
                                <div className="date-filter-form">
                                    <div className="padAlignRight">
                                        <p>Start and End Dates:</p>
                                    </div>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={startDate}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="fromDate"
                                            id="startDate" max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={new Date()}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="toDate"
                                            id="endDate"
                                            min={startDate} max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>

                                    <input
                                        type="submit"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        value="Search"
                                    />
                                </div>
                            </form>

                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Water Meters Entries-{ siteName }</strong></div>
                        <Grid className="display"
                              style={{
                                  height: "800px",
                              }}

                        >
                            <Cell small={12} medium={12} large={12}>
                            <ExcelExport data={meterEntryList} ref={_export} fileName={siteName + '_' + waterName +'.xlsx'}>

                                <DataGrid
                                    style={{
                                        height: "800px",
                                    }}
                                    data={filterBy(meterEntryList, filter)}
                                    filterable={true}
                                    filter={filter}
                                    onFilterChange={(e) => setFilter(e.filter)}
                                    sortable={true}
                                    sort={sort}
                                    onSortChange={sortChange}
                                >
                                    <Toolbar>
                                        <button
                                            title="Export Excel"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={excelExport}
                                        >
                                            Export to Excel
                                        </button>
                                        <Link to={'/input/add/water-entry/'+site}
                                            title="Add Water Meter"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        >
                                            Add Water Entry 
                                        </Link>
                                    </Toolbar>

                                    <Column field="id" title="id" width="150" />
                                    <Column field="meter_name" title="Meter Name" width="150" />
                                    <Column field="date_added" title="Date" />
                                    <Column field="amount" title="Amount" format="{0:n2}"/>
                                    <Column field="meter_reading" title="Meter Reading" format="{0:n2}"/>
                                    <Column field="file_upload" title="File Upload"/>
                                </DataGrid>
                            </ExcelExport>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default WaterMeters;