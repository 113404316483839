import {Grid, Cell} from "react-foundation";
import {Link, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import { Slider, SliderLabel } from "@progress/kendo-react-inputs";
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem, ChartCategoryAxisTitle,
    ChartLegend, ChartSeries, ChartSeriesItem,
    ChartTitle,
    ChartTooltip
} from "@progress/kendo-react-charts";
import {Grid as DataGrid} from "@progress/kendo-react-grid/dist/npm/Grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import axios from "axios";
import $ from "jquery";
import {useEffect, useState} from "react";
import * as React from "react";

function TwinLanding() {
    const { site } = useParams();
    const value = new Date();

    const [predictions, setPredictions] = useState([]);
    const [predictionsCats, setPredictionsCats] = useState([]);

    useEffect(() => {
        const getPredictiveData = async () => {
            axios.get(
                'https://sensai-devnew-functions.z01.azurefd.net/api/v1/Predictions?to=2023-04-30&from=2023-03-24&code=mUy77pPmjFcbWn_JM7ostzzmBJ3Afe1A8mEPAT2-yWDNAzFuSbU0IQ=='
            ).then(function(response) {
                let chartData = [];
                chartData.push({
                    name: "Predicted Usage",
                    data: response.data
                });

                setPredictions(chartData);

                let dateList = [];
                let setDate = new Date('2023-03-24');
                for(var i = 0; i < (response.data).length; i++) {
                    dateList.push(setDate.getDate()+'/'+(setDate.getMonth()+1));

                    setDate.setDate(setDate.getDate() + 1);
                }

                setPredictionsCats(dateList);
            });
        }
        getPredictiveData();
    }, []);

    const SharedTooltip = (props) => {
        const { category, points } = props;
        return (
            <div>
                <div>{category}</div>
                {points.map((point, i) => (
                    <div key={i}>
                        {point.series.name} : {point.value}
                    </div>
                ))}
            </div>
        );
    };
    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

    const labelContent = (props) => {
        let contribNumber = Number(props.dataItem.share).toLocaleString(undefined, {
            minimumFractionDigits: 2,
        });
        return `${props.dataItem.kind}:\r\n(${contribNumber} kgCO2)`;
    };

    return (
        <Grid>
            <Cell small={12} medium={2} large={2}>
                <div className="energyNavigation">
                    <h5>Scope 1 Emission Sources</h5>
                    <ul>
                        <li>
                            <Link to={'/energy/'+site+'/2'} className="nav-link">Natural Gas</Link>
                        </li>
                        <li>
                            <Link to={'/energy/'+site+'/15'} className="nav-link">Burning Oil</Link>
                        </li>
                        <li>
                            <Link to={'/energy/'+site+'/16'} className="nav-link">Gas Oil</Link>
                        </li>
                    </ul>
                    <hr/>
                    <h5>Scope 2 Emission Sources</h5>
                    <ul>
                        <li>
                            <Link to={'/energy/'+site+'/1'} className="nav-link">Electric</Link>
                        </li>
                    </ul>
                    <hr/>
                    <h5>Usage Type Sub-Sections</h5>
                    <ul className="submenuNav">
                        <li>
                            <Link to={'/meter/'+site+'/1'} className="nav-link">IT</Link>
                        </li>
                        <li>
                            <Link to={'/meter/'+site+'/2'} className="nav-link">Kitchen</Link>
                        </li>
                    </ul>
                    <hr/>
                    <h5>Uploads</h5>
                    <ul>
                        <li>
                            <Link to={'/input/energy/'+site} className="nav-link">Energy Meter Upload</Link>
                        </li>
                    </ul>
                    <hr/>
                    <h5>Digital Twin</h5>
                    <ul>
                        <li>
                            <Link to={'/twin/energy/'+site} className="nav-link">Digital Twin Overview</Link>
                        </li>
                    </ul>
                </div>
            </Cell>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion">
                                <Link to={'/dashboard/energy/'+site+''} className="linkBack">&#11164; Back to Energy Dashboard</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion padAlignRight">
                                <p>Start and End Dates:</p>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date(2023,2,24,0,0,0)} defaultShow={false} format={"dd-MM-yyyy"} name="startDate" id="startDate" />
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date(2023,3,30,0,0,0)} defaultShow={false} format={"dd-MM-yyyy"} name="endDate" id="endDate" />
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Digital Twin Demo</strong></div>
                        <Grid className="display">
                            <Cell small={12} medium={12} large={12}>
                                <div className="dashboard">
                                    <div className="dashContainerCompact">
                                        <div className="dashContainerHeaderCompact"><strong>Predicted Electric Usage</strong> <span className="dataSubNote">- 24th Mar 2023 to 30th April 2023</span></div>
                                        <Grid className="display">
                                            <Cell small={12} medium={12} large={12}>
                                                <div className="notesHeading">
                                                    Predicted kWh usage values are generated using previously stored usage data points, using the day and previous same day records for the same day(s), and presents a variance within 5% of the mean daily record set.
                                                </div>
                                            </Cell>
                                            <Cell small={12} medium={12} large={12}>
                                                <div id="chart80">
                                                    <Chart>
                                                        <ChartTooltip shared={true} render={sharedTooltipRender} />
                                                        <ChartTitle text="Electric Predicted Usage (kWh) for Selected Date Range" />
                                                        <ChartLegend position="bottom" />
                                                        <ChartCategoryAxis>
                                                            <ChartCategoryAxisItem categories={predictionsCats}>
                                                                <ChartCategoryAxisTitle text="Prediction Dates" />
                                                            </ChartCategoryAxisItem>
                                                        </ChartCategoryAxis>
                                                        <ChartSeries>
                                                            {predictions.map((item, idx) => (
                                                                <ChartSeriesItem
                                                                    key={idx}
                                                                    type="column"
                                                                    tooltip={{
                                                                        visible: true,
                                                                    }}
                                                                    data={item.data}
                                                                    name={item.name}
                                                                    stack={false}
                                                                />
                                                            ))}
                                                        </ChartSeries>
                                                    </Chart>
                                                </div>
                                            </Cell>
                                        </Grid>
                                    </div>
                                </div>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default TwinLanding;