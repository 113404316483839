import React, { useState } from 'react';
import { Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartTitle, ChartLegend } from '@progress/kendo-react-charts';
import { DropDownList } from "@progress/kendo-react-dropdowns"
import {Grid as DataGrid, GridColumn as Column, GridNoRecords, GridToolbar, GridColumn} from "@progress/kendo-react-grid";
import { EmissionsByKgco2eData } from './tableData';
import {Grid, Cell} from "react-foundation";


const EmissionsByKgco2eReport = () => {
    const [dropdownOption, setDropdownOption] = useState({ value: '1', label: '1' });
    const dropdownOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
    ];
    const lineData = [
        { name: 'Energy', data: [150, 200, 250, 300, 350, 400] },
        { name: 'Products and Services', data: [100, 150, 200, 250, 300, 350] },
        { name: 'Transport', data: [50, 100, 150, 200, 250, 300] },
        { name: 'Waste', data: [200, 250, 300, 350, 400, 450] },
    ];
    const lineCategories = ['2019', '2020', '2021', '2022', '2023', '2024'];

    const tableHeadings = [
        {field: 'year', title: 'Year'},
        {field: 'energy', title: 'Energy'},
        {field: 'products_and_services', title: 'Products and Services'},
        {field: 'transport', title: 'transport'},
        {field: 'waste', title: 'Waste'},
    ]

    const updateDropdown = (event) => {
        setDropdownOption(event.value);
        console.log('Selected', event.value);
    };
    return (
        <div style={{padding: '0 1rem'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 0'}}>
                    <span>Emissions By KgCo2e</span>
                    <div style={{padding: '1rem 0'}}>
                        <DropDownList 
                            data={dropdownOptions}
                            onChange={updateDropdown}
                            value={dropdownOption}
                            textField="label"
                            dataValueField="value"
                            placeholder="Energy"
                            style={{ width: '200px', margin: '0 0.25rem' }}
                        />
                        <DropDownList 
                            data={dropdownOptions}
                            onChange={updateDropdown}
                            value={dropdownOption}
                            textField="label"
                            dataValueField="value"
                            placeholder="Products and Services"
                            style={{ width: '200px', margin: '0 0.25rem' }}
                        />
                        <DropDownList 
                            data={dropdownOptions}
                            onChange={updateDropdown}
                            value={dropdownOption}
                            textField="label"
                            dataValueField="value"
                            placeholder="Transport"
                            style={{ width: '200px', margin: '0 0.25rem' }}
                        />
                        <DropDownList 
                            data={dropdownOptions}
                            onChange={updateDropdown}
                            value={dropdownOption}
                            textField="label"
                            dataValueField="value"
                            placeholder="Waste"
                            style={{ width: '200px', margin: '0 0.25rem' }}
                        />
                    </div>
                </div>
                <Grid>
                    <Cell large={8}>
                    <Chart style={{ height: 350 }}>
                        <ChartTitle text="" />
                        <ChartLegend position="bottom" orientation="horizontal" />
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem categories={lineCategories} />
                        </ChartCategoryAxis>
                        <ChartSeries>
                        {lineData.map((series, index) => (
                            <ChartSeriesItem
                                key={index}
                                type="line"
                                tooltip={{ visible: true }}
                                name={series.name}
                                data={series.data}
                            />
                        ))}
                        </ChartSeries>
                    </Chart>
                    </Cell>
                    <Cell large={4}>
                        <DataGrid data={EmissionsByKgco2eData}>
                            {
                                tableHeadings.map((col, index) => {
                                    <Column key={index} field={col.field} title={col.title} />
                                })
                            }
                        </DataGrid>
                    </Cell>
                </Grid>
        </div>
    )
}

export default EmissionsByKgco2eReport