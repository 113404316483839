import {Grid, Cell} from "react-foundation";
import {Link, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import $ from "jquery";
import React, {useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
import {GridToolbar as Toolbar} from "@progress/kendo-react-grid/dist/npm/GridToolbar";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid as DataGrid} from "@progress/kendo-react-grid/dist/npm/Grid";
import {filterBy, orderBy} from "@progress/kendo-data-query";
import {ExcelExport} from "@progress/kendo-react-excel-export";
// import * as React from "@types/react";

function WasteEntries() {
    const [siteName, setSiteName] = useState('');
    const [energyName, setEnergyName] = useState('');
    const [meterEntryList, setMeterEntryList] = React.useState([]);
    const [WasteDataTable, setWasteDataTable] = useState([]);

    const params = useParams();
    const site = params['site'];

    const value = new Date();

    const _export = React.useRef(null);
    const [filter, setFilter] = React.useState();
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };
    const initialSort = [
        {
            field: "id",
            dir: "desc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);

    const sortChange = (event) => {
        setMeterEntryList(getMeterEntries(event.sort));
        setSort(event.sort);
    };
    const getMeterEntries = (sort) => {
        return orderBy(meterEntryList, sort);
    };

    const initialDataState = {
        skip: 0,
        take: 25,
    };

    const [page, setPage] = React.useState(initialDataState);
    const [pageSizeValue, setPageSizeValue] = React.useState();
    const pageChange = (event) => {
        const targetEvent = event.targetEvent;
        const take =
            targetEvent.value === "All" ? WasteDataTable.length : event.page.take;
        if (targetEvent.value) {
            setPageSizeValue(targetEvent.value);
        }
        setPage({
            ...event.page,
            take,
        });
    };


    const [searchFormState, setSearchFormState] = React.useState({
        fromDate: new Date(2016,8,1),
        toDate: new Date(),
        
    });
    const [startDate, setStartDate] = useState(searchFormState.fromDate);
    const [endDate, setEndDate] = useState(searchFormState.toDate);
    const handleChange = (event) => {
        setSearchFormState({
            ...searchFormState,
            [event.target.name]: event.target.value,
        });
    };
    const handleDateSearch = (event) => {
        event.preventDefault();
        setStartDate(searchFormState.fromDate);
        setEndDate(searchFormState.toDate);
    };

    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            });
        }
        loadSite();

        const loadWasteUsageTable= async () => {

            let url = '/pillar/2/site-waste-entries';
            let params = {
                site_id: site,
            };

            axiosApi.get(
                url,
                {
                    params:  params
                }
            ).then(function (response) {
                let entries = response['data']['data']['list'];
                const waste_data_table = [];
                for (let i = 0; i < entries.length; i++) {

                    let entry = entries[i];

                    console.log(entry);

                    waste_data_table.push({
                        "Date": entry['added_on'],
                        "Amount": entry['amount'],
                        "EWCCode": entry['waste']['code'],
                        "WasteContainer": entry['waste_container']['name'],
                        "WasteDestination":entry['waste_destination']['name'],
                        "WasteProcess": entry['waste_process']['name'],
                        "VehicleType": entry['pillar_two_vehicle_type']['name'],
                        "KGCO2Total": 0.00,
                        "KGCO2eTotal": 0.00,
                    });

                }
                setWasteDataTable(waste_data_table);
            });
        }
        loadWasteUsageTable();
    },[startDate,endDate,site]);
    return (
        <Grid>
            <Cell small={12} medium={2} large={2}>
                <div className="energyNavigation">
                </div>
            </Cell>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={12} large={2}>
                            <div className="padRegion">
                                <Link to={'/dashboard/wasteLanding/' + site}>&#11164; Back to Waste Dashboard</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={12} large={4}></Cell>

                        <Cell small={12} medium={12} large={6}>
                            <form className="k-form" onSubmit={handleDateSearch}>
                                <div className="date-filter-form">
                                    <div className="padAlignRight">
                                        <p>Start and End Dates:</p>
                                    </div>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={startDate}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="fromDate"
                                            id="startDate" max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={new Date()}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="toDate"
                                            id="endDate"
                                            min={startDate} max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>

                                    <input
                                        type="submit"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        value="Search"
                                    />
                                </div>
                            </form>

                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Waste Entries -{ siteName }</strong></div>
                        <Grid className="display"
                              style={{
                                  height: "800px",
                              }}

                        >
                            <Cell small={12} medium={12} large={12}>
                            <ExcelExport data={WasteDataTable} ref={_export} fileName={siteName + '_waste.xlsx'}>

                                <DataGrid
                                    style={{
                                        height: "800px",
                                    }}

                                    data={filterBy(WasteDataTable, filter).slice(page.skip, page.take + page.skip)}
                                    skip={page.skip}
                                    take={page.take}
                                    total={filterBy(WasteDataTable, filter).length}
                                    pageable={{
                                        buttonCount: 4,
                                        pageSizes: [5, 10, 15, "All"],
                                        pageSizeValue: pageSizeValue,
                                    }}
                                    onPageChange={pageChange}
                                    filterable={true}
                                    filter={filter}
                                    onFilterChange={(e) => setFilter(e.filter)}
                                    sortable={true}
                                    sort={sort}
                                    onSortChange={sortChange}
                                >
                                    <Toolbar>
                                        <button
                                            title="Export Excel"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={excelExport}
                                        >
                                            Export to Excel
                                        </button>
                                    </Toolbar>

                                    <Column field="Date" title="Date" />
                                    <Column field="Amount" title="Amount" format="{0:n2}"/>
                                    <Column field="EWCCode" title="EWC Code" format="{0:n2}"/>
                                    <Column field="WasteContainer" title="Waste Container" format="{0:n2}"/>
                                    <Column field="WasteProcess" title="Waste Process" format="{0:n2}"/>
                                    <Column field="WasteDestination" title="Waste Destination" format="{0:n2}"/>
                                    <Column field="VehicleType" title="Vehicle Type" format="{0:n2}"/>
                                    <Column field="KGCO2Total" title="KGCO2 Total" format="{0:n2}"/>
                                    <Column field="KGCO2eTotal" title="KGCO2e Total" format="{0:n2}"/>
                                </DataGrid>
                            </ExcelExport>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default WasteEntries;