import React, { useState } from 'react';
import ComparativeFilter from "./comparativeFilter"
import {Grid, Cell} from "react-foundation";
import { Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartTitle, ChartLegend } from '@progress/kendo-react-charts';
import { Card, CardHeader, CardTitle, CardBody, CardActions, CardImage, CardSubtitle, Avatar } from '@progress/kendo-react-layout';
import {Link, NavLink, useNavigate, useLocation} from "react-router-dom"
import EmissionsByScopeReport from './EmissionsByScopeReport';
import EmissionsByTypeReport from './EmissionsByTypeReport';
import EmissionsByKgco2eReport from './EmissionsByKgco2eReport';

const ComparativeDashboard = () => {
    
        const [baselineYear, setBaselineYear] = useState('2019');
        const [selectedYears, setSelectedYears] = useState([{ value: '2019', label: '2019' }]);
        const [normalizationMetric, setNormalizationMetric] = useState({ value: 'number_of_staff', label: 'Number of Staff' });
        const [emissionsCategory, setEmissionsCategory] = useState({ value: 'all', label: 'All' });

        const years = [
            { value: '2019', label: '2019' },
            { value: '2020', label: '2020' },
            { value: '2021', label: '2021' },
            { value: '2022', label: '2022' },
            { value: '2023', label: '2023' },
            { value: '2024', label: '2024' },
            { value: '2025', label: '2025' },
        ];
    
        const normalizationMetrics = [
            { value: 'number_of_staff', label: 'Number of Staff' },
            { value: 'per_100m2', label: 'Per 100m2' },
            { value: 'per_1m', label: 'Per £1m' },
            { value: 'per_100k', label: 'Per £100,000' },
        ];
    
        const emissionsCategories = [
            { value: 'all', label: 'All' },
            { value: 'energy', label: 'Energy (Build Environment)' },
            { value: 'transport', label: 'Transport' },
            { value: 'waste', label: 'Waste' },
            { value: 'land_use', label: 'Land Use' },
        ];

        const lineData = [
            { name: 'Energy', data: [150, 200, 250, 300, 350, 400] },
            { name: 'Products and Services', data: [100, 150, 200, 250, 300, 350] },
            { name: 'Transport', data: [50, 100, 150, 200, 250, 300] },
            { name: 'Waste', data: [200, 250, 300, 350, 400, 450] },
        ];
        const lineCategories = ['2019', '2020', '2021', '2022', '2023', '2024'];
        
        //events
        const updateNormalizationMetric = (event) => {
            setNormalizationMetric(event.value);
            console.log('Selected Normalization Metric:', event.value);
        };
    
        const updateEmissionsCategory = (event) => {
            setEmissionsCategory(event.value);
            console.log('Selected Emissions Category:', event.value);
        };
    
        const updateYear = (event) => {
            const selected = event.target.value;
          
            const updatedYears = [
              ...selected,
              { value: baselineYear, label: baselineYear },
            ];
          
            const uniqueYears = Array.from(
              new Set(updatedYears.map((year) => year.value))
            ).map((value) => years.find((year) => year.value === value));
          
            setSelectedYears(uniqueYears);
          };
    return (
        <div style={{margin: '2rem 2rem 0 2rem', padding: '1rem'}}>
        <ComparativeFilter
            year={selectedYears}
            normalizationMetric={normalizationMetric}
            emissionsCategory={emissionsCategory}
            years={years}
            normalizationMetrics={normalizationMetrics}
            emissionsCategories={emissionsCategories}
            updateYear={updateYear}
            updateNormalizationMetric={updateNormalizationMetric}
            updateEmissionsCategory={updateEmissionsCategory}
        />

        <Grid className="display" style={{backgroundColor: '#ffffff', padding: '1rem', margin: '2rem 0', borderRadius: '3px'}}>
            <Cell small={12} large={6} style={{padding: '0 0.5rem'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 0'}}>
                    <span>Emissions By Scope</span>
                    <Link to={'/emissions-by-scope'} className="nav-link">View</Link>
                </div>
                <Card>
                    <EmissionsByScopeReport/>
                </Card>
            </Cell>
            <Cell small={12} large={6} style={{padding: '0 0.5rem'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 0'}}>
                    <span>Emissions By Type</span>
                    <Link to={'/emissions-by-scope'} className="nav-link">View</Link>
                </div>
                <Card>
                    <EmissionsByTypeReport/>
            </Card>
            </Cell>
            <Cell small={12} large={12} style={{padding: '0 0.5rem'}}>
                <Card>
                    <EmissionsByKgco2eReport/>
                </Card>
            </Cell>
        </Grid>
        </div>
    )
}

export default ComparativeDashboard