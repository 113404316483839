import * as React from "react";
import {Grid, Cell} from "react-foundation";
import { Grid as DataGrid, GridColumn as Column, GridToolbar as Toolbar } from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

import "hammerjs";
import {Link, useNavigate, useParams, useLocation} from "react-router-dom";
import {isNullOrEmptyString} from "@progress/kendo-data-query/dist/npm/utils";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import {formatDate, formatNumber} from '@progress/kendo-intl';
import { orderBy } from "@progress/kendo-data-query";
import $ from "jquery";
import {useCallback, useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
// import WasteNavbar from "./WasteNavbar";
import {FormatWasteEntries} from "./WasteHelper";
import WasteChart from "./WasteChart";
import WasteNavBar from "./WasteNavBar";
// import WaterDrillDownChart from "./WaterDrillDownChart";


function WasteLanding() {

    const navigate = useNavigate();
    const location = useLocation();

    const [wasteName, setWasteName] = useState('');
    const [wasteMeasure, setWasteMeasure] = useState('');

    const [wasteTonnageDataChart, setWasteTonnageDataChart] = useState([]);
    const [processEmissionsDataChart, setProcessEmissionsDataChart] = useState([]);
    const [wasteTransportEmissionsDataChart, setWasteTransportEmissionsDataChart] = useState([]);
    const [wasteDataTable, setWasteDataTable] = useState([]);
    const [totalWasteSummary, setTotalWasteSummary] = useState([]);
    const [totalSummaryProcessEmissions, setTotalSummaryProcessEmissions] = useState([]);
    const [totalSummaryWasteTransportEmissions, setTotalSummaryWasteTransportEmissions] = useState([]);
    const [wasteMaterialDataChart, setWasteMaterialDataChart] = useState([]);
    const [wasteDestinationsDataChart, setWasteDestinationsDataChart] = useState([]);

    const [siteName, setSiteName] = useState('');

    const params = useParams();
    const id = params['id'];
    const site = params['site'];


    const [searchFormState, setSearchFormState] = React.useState({
        fromDate: new Date(2016,8,1),
        toDate: new Date(),
    });
    const [startDate, setStartDate] = useState(searchFormState.fromDate);
    const [endDate, setEndDate] = useState(searchFormState.toDate);
    const handleChange = (event) => {
        setSearchFormState({
            ...searchFormState,
            [event.target.name]: event.target.value,
        });
    };
    const handleDateSearch = (event) => {
        event.preventDefault();
        setStartDate(searchFormState.fromDate);
        setEndDate(searchFormState.toDate);
    };


    const initialSort = [
        {
            field: "Date",
            dir: "desc",
        },
    ];

    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const [sort, setSort] = React.useState(initialSort);


    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            });
        }
        loadSite();
    },[site]);

    useEffect(() => {

        const loadWasteUsageGraph = async () => {

            let url = '/pillar/2/site-waste-entries-summary';
            let params = {
                site_id: site,
                start_date: startDate,
                end_date: endDate,
            };

            axiosApi.get(
                url,
                {
                    params:  params
                }
            ).then(function (response) {
                let data = response['data']['data'];

                let result = FormatWasteEntries(data, true);
                //console.log(result);
                setWasteTonnageDataChart(result['waste_tonnage_by_month']);
                setProcessEmissionsDataChart(result['waste_process_emissions']);
                setWasteTransportEmissionsDataChart(result['waste_transport_emissions']);
                setTotalWasteSummary(result['total_summary']);
                setTotalSummaryProcessEmissions(result['total_summary_process_emissions']);
                setTotalSummaryWasteTransportEmissions(result['total_summary_waste_transport_emissions']);
                setTotalSummaryWasteTransportEmissions(result['total_summary_waste_transport_emissions']);

                setWasteMaterialDataChart(result['waste_material_chart']);
                setWasteDestinationsDataChart(result['waste_destinations_chart'])

            });

        }
        loadWasteUsageGraph();
    },[id,startDate,endDate,site]);


    useEffect(() => {

        const loadWasteUsageTable= async () => {
            let url = '/pillar/2/site-waste-entries';
            let params = {
                site_id: site,
            };

            axiosApi.get(
                url,
                {
                    params:  params
                }
            ).then(function (response) {
                let entries = response['data']['data']['list'];
                const waste_data_table = [];
                for (let i = 0; i < entries.length; i++) {
                    let kgco2 = 0;
                    let kgco2e = 0;

                    let entry = entries[i];

                    for(let emission of entry['ghg_emissions']) {
                        if(emission['emission_measurement_id'] == 3) {
                            kgco2e = emission['amount'];
                        } else if(emission['emission_measurement_id'] == 4) {
                            kgco2 = emission['amount'];
                        }
                    }

                    waste_data_table.push({
                        "Date": entry['added_on'],
                        "Amount": entry['amount'],
                        "EWCCode": entry['waste']['code'],
                        "WasteContainer": entry['waste_container']['name'],
                        "WasteDestination":entry['waste_destination']['name'],
                        "WasteProcess": entry['waste_process']['name'],
                        "VehicleType": entry['pillar_two_vehicle_type']['name'],
                        "KGCO2Total": kgco2,
                        "KGCO2eTotal": kgco2e,
                    });
                }
                setWasteDataTable(waste_data_table);
            });
        }
        loadWasteUsageTable();
    },[id,startDate,endDate,site]);



    return (
        <Grid>
            <WasteNavBar/>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={12} large={2}>
                            <div className="padRegion">
                                <Link to={'/sitelanding/' + site} className="linkBack">&#11164; Back to Site Landing</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={12} large={4}></Cell>

                        <Cell small={12} medium={12} large={6}>
                            <form className="k-form" onSubmit={handleDateSearch}>
                                <div className="date-filter-form">
                                    <div className="padAlignRight">
                                        <p>Start and End Dates:</p>
                                    </div>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={startDate}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="fromDate"
                                            id="startDate" max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={new Date()}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="toDate"
                                            id="endDate"
                                            min={startDate} max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>

                                    <input
                                        type="submit"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        value="Search"
                                    />
                                </div>
                            </form>

                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Waste Summary -{ siteName }</strong></div>
                        <Grid className="display">
                            <TabStrip selected={selected} onSelect={handleSelect} style={{ width: "100%" }}>
                                <TabStripTab title={ wasteName + " Waste Tonnage"}>
                                    <Cell small={12} medium={12} large={12}>
                                        <Cell small={12} medium={12} large={12}>
                                            <WasteChart
                                                key = {Math.random()}
                                                chartData={wasteTonnageDataChart}
                                                title={"Waste Tonnage By Month"}
                                                xaxisTitle = {wasteMeasure}
                                                yaxisTitle = {"Waste Actual"}
                                                type = "column"
                                                color = "green"
                                            />
                                        </Cell>

                                        <Cell small={12} medium={12} large={12}>
                                            <Grid className="display">
                                                <Cell small={12} medium={4} large={4}>
                                                    <div className="quickSelectTab">
                                                        <h4>Tonnage</h4>
                                                        <p>Total: {formatNumber(totalWasteSummary['amount'] ?? 0, 'n2') }</p>
                                                        <p>Total KGCO2: {formatNumber(totalWasteSummary['kgco2'] ?? 0, 'n2') }</p>
                                                        <p>Total KGO2e: {formatNumber(totalWasteSummary['kgco2e'] ?? 0, 'n2') }</p>
                                                    </div>
                                                </Cell>
                                                <Cell small={12} medium={4} large={4}>
                                                    <div className="quickSelectTab">
                                                        <h4>Process Emissions</h4>
                                                        <p>Total KGCO2e: { formatNumber(totalWasteSummary['kgco2e'] ?? 0, 'n2') }</p>
                                                        <p>Total Recycled KGCO2e: {formatNumber(totalSummaryProcessEmissions['total_reuse'] ?? 0, 'n2')}</p>
                                                        <p>Total Recovered KGCO2e: {formatNumber(totalSummaryProcessEmissions['total_incinerate'] ?? 0, 'n2')}</p>
                                                    </div>
                                                </Cell>
                                                {/*
                                                <Cell small={12} medium={4} large={4}>
                                                    <div className="quickSelectTab">
                                                        <h4>Transport Emissions</h4>
                                                        <p>Total KGCO2: {totalSummaryWasteTransportEmissions['kgco2'] ?? 0}</p>
                                                        <p>Total KGO2e: {totalSummaryWasteTransportEmissions['kgco2e'] ?? 0}</p>
                                                    </div>
                                                </Cell>
                                                */}
                                            </Grid>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <DataGrid
                                                style={{
                                                    height: "400px",
                                                }}
                                                data={orderBy(wasteDataTable, sort)}
                                                sortable={true}
                                                sort={sort}
                                                onSortChange={(e) => {
                                                    setSort(e.sort);
                                                }}
                                            >
                                                <Toolbar>
                                                    <Link to={'/waste-entries/' + site}  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                                                        See All Inputs
                                                    </Link>
                                                </Toolbar>
                                                <Column field="Date" title="Date" />
                                                <Column field="Amount" title="Amount" format="{0:n2}"/>
                                                <Column field="EWCCode" title="EWC Code" format="{0:n2}"/>
                                                <Column field="WasteContainer" title="Waste Container" format="{0:n2}"/>
                                                <Column field="WasteProcess" title="Waste Process" format="{0:n2}"/>
                                                <Column field="WasteDestination" title="Waste Destination" format="{0:n2}"/>
                                                <Column field="VehicleType" title="Vehicle Type" format="{0:n2}"/>
                                                <Column field="KGCO2Total" title="KGCO2 Total" format="{0:n2}"/>
                                                <Column field="KGCO2eTotal" title="KGCO2e Total" format="{0:n2}"/>
                                            </DataGrid>
                                        </Cell>
                                    </Cell>
                                </TabStripTab>

                                <TabStripTab title={ wasteName + " Process Emissions"}>
                                    <Cell small={12} medium={12} large={12}>
                                        <Cell small={12} medium={12} large={12}>
                                            <WasteChart
                                                key = {Math.random()}
                                                chartData={processEmissionsDataChart}
                                                title={"Process Emissions By Month"}
                                                type = "column"
                                            />
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <Grid className="display">
                                                <Cell small={12} medium={4} large={4}>
                                                    <div className="quickSelectTab">
                                                        <h4>Tonnage</h4>
                                                        <p>Total: {formatNumber(totalWasteSummary['amount'] ?? 0, 'n2') }</p>
                                                        <p>Total KGCO2: {formatNumber(totalWasteSummary['kgco2'] ?? 0, 'n2') }</p>
                                                        <p>Total KGO2e: {formatNumber(totalWasteSummary['kgco2e'] ?? 0, 'n2') }</p>
                                                    </div>
                                                </Cell>
                                                <Cell small={12} medium={4} large={4}>
                                                    <div className="quickSelectTab">
                                                        <h4>Process Emissions</h4>
                                                        <p>Total KGCO2e: { formatNumber(totalWasteSummary['kgco2e'] ?? 0, 'n2') }</p>
                                                        <p>Total Recycled KGCO2e: {formatNumber(totalSummaryProcessEmissions['total_reuse'] ?? 0, 'n2')}</p>
                                                        <p>Total Recovered KGCO2e: {formatNumber(totalSummaryProcessEmissions['total_incinerate'] ?? 0, 'n2')}</p>
                                                    </div>
                                                </Cell>
                                                {/*
                                                <Cell small={12} medium={4} large={4}>
                                                    <div className="quickSelectTab">
                                                        <h4>Transport Emissions</h4>
                                                        <p>Total KGCO2: {totalSummaryWasteTransportEmissions['kgco2'] ?? 0}</p>
                                                        <p>Total KGO2e: {totalSummaryWasteTransportEmissions['kgco2e'] ?? 0}</p>
                                                    </div>
                                                </Cell> */}
                                            </Grid>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                        </Cell>

                                        <Cell small={12} medium={12} large={12}>
                                            <DataGrid
                                                style={{
                                                    height: "400px",
                                                }}
                                                data={orderBy(wasteDataTable, sort)}
                                                sortable={true}
                                                sort={sort}
                                                onSortChange={(e) => {
                                                    setSort(e.sort);
                                                }}
                                            >
                                                <Toolbar>
                                                    <Link to={'/waste-entries/' + site}  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                                                        See All Inputs
                                                    </Link>
                                                </Toolbar>
                                                <Column field="Date" title="Date" />
                                                <Column field="Amount" title="Amount" format="{0:n2}"/>
                                                <Column field="EWCCode" title="EWC Code" format="{0:n2}"/>
                                                <Column field="WasteContainer" title="Waste Container" format="{0:n2}"/>
                                                <Column field="WasteProcess" title="Waste Process" format="{0:n2}"/>
                                                <Column field="WasteDestination" title="Waste Destination" format="{0:n2}"/>
                                                <Column field="VehicleType" title="Vehicle Type" format="{0:n2}"/>
                                                <Column field="KGCO2Total" title="KGCO2 Total" format="{0:n2}"/>
                                                <Column field="KGCO2eTotal" title="KGCO2e Total" format="{0:n2}"/>
                                            </DataGrid>
                                        </Cell>
                                    </Cell>
                                </TabStripTab>


                                <TabStripTab title={"Waste Breakdown"}>
                                        <Cell small={12} medium={12} large={12}>
                                            <Cell small={12} medium={12} large={12}>
                                                <WasteChart
                                                    title = "Waste Material Breakdown"
                                                    chartData={wasteMaterialDataChart}
                                                    categoryAxisTitle = {"Material"}
                                                    angle = '0'
                                                    type = "bar"
                                                />
                                            </Cell>

                                        </Cell>

                                        <Cell small={12} medium={12} large={12}>
                                            <Cell small={12} medium={12} large={12}>
                                                <WasteChart
                                                    title = "Waste Destionations Breakdown"
                                                    chartData={wasteDestinationsDataChart}
                                                    categoryAxisTitle = {"Destination"}
                                                    angle = '0'
                                                    color = 'green'
                                                    type = "bar"
                                                />
                                            </Cell>

                                        </Cell>
                                </TabStripTab>
                                {/*
                                <TabStripTab title={ wasteName + " Transport Emissions"}>
                                    <Cell small={12} medium={12} large={12}>
                                        <Cell small={12} medium={12} large={12}>
                                            <WasteChart
                                                chartData={wasteTransportEmissionsDataChart}
                                                title={"Transport Emissions By Month"}
                                                xaxisTitle = {wasteMeasure}
                                                yaxisTitle = {"Transport Emissions Actual"}
                                                type = "column"
                                                color = "green"
                                            />
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <Grid className="display">
                                                <Cell small={12} medium={4} large={4}>
                                                    <div className="quickSelectTab">
                                                        <h4>Tonnage</h4>
                                                        <p>Total: {totalSummaryTonnage['amount'] ?? 0}</p>
                                                        <p>Total KGCO2: {totalSummaryTonnage['kgco2'] ?? 0}</p>
                                                        <p>Total KGO2e: {totalSummaryTonnage['kgco2e'] ?? 0}</p>
                                                    </div>
                                                </Cell>
                                                <Cell small={12} medium={4} large={4}>
                                                    <div className="quickSelectTab">
                                                        <h4>Process Emissions</h4>
                                                        <p>Total KGCO2e: {totalSummaryProcessEmissions['total_amount'] ?? 0}</p>
                                                        <p>Total Reuse KGCO2e: {totalSummaryProcessEmissions['total_reuse'] ?? 0}</p>
                                                        <p>Total Incinerate KGO2e: {totalSummaryProcessEmissions['total_incinerate'] ?? 0}</p>
                                                    </div>
                                                </Cell>
                                                <Cell small={12} medium={4} large={4}>
                                                    <div className="quickSelectTab">
                                                        <h4>Transport Emissions</h4>
                                                        <p>Total KGCO2: {totalSummaryWasteTransportEmissions['kgco2'] ?? 0}</p>
                                                        <p>Total KGO2e: {totalSummaryWasteTransportEmissions['kgco2e'] ?? 0}</p>
                                                    </div>
                                                </Cell>
                                            </Grid>
                                        </Cell>

                                        <Cell small={12} medium={12} large={12}>
                                            <DataGrid
                                                style={{
                                                    height: "400px",
                                                }}
                                                data={orderBy(wasteDataTable, sort)}
                                                sortable={true}
                                                sort={sort}
                                                onSortChange={(e) => {
                                                    setSort(e.sort);
                                                }}
                                            >
                                                <Toolbar>
                                                    <Link to={'/waste-entries/' + site}  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                                                        See All Inputs
                                                    </Link>
                                                </Toolbar>
                                                <Column field="Date" title="Date" />
                                                <Column field="Amount" title="Amount" format="{0:n2}"/>
                                                <Column field="EWCCode" title="EWC Code" format="{0:n2}"/>
                                                <Column field="WasteContainer" title="Waste Container" format="{0:n2}"/>
                                                <Column field="WasteProcess" title="Waste Process" format="{0:n2}"/>
                                                <Column field="WasteDestination" title="Waste Destination" format="{0:n2}"/>
                                                <Column field="VehicleType" title="Vehicle Type" format="{0:n2}"/>
                                                <Column field="KGCO2Total" title="KGCO2 Total" format="{0:n2}"/>
                                                <Column field="KGCO2eTotal" title="KGCO2e Total" format="{0:n2}"/>
                                            </DataGrid>
                                        </Cell>
                                    </Cell>
                                </TabStripTab>
                                */}

                            </TabStrip>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default WasteLanding;