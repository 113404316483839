import * as React from "react";
import {Grid, Cell} from "react-foundation";
import { Grid as DataGrid, GridColumn as Column, GridToolbar as Toolbar } from "@progress/kendo-react-grid";

import "hammerjs";
import {Link, useNavigate, useParams} from "react-router-dom";
import {isNullOrEmptyString} from "@progress/kendo-data-query/dist/npm/utils";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { orderBy } from "@progress/kendo-data-query";
import $ from "jquery";
import {useCallback, useEffect, useState} from "react";
import axiosApi from "../api/axiosInstance";
import EnegryNavbar from "./EnergyNavbar";
import {parseNumber} from "./form/format-helper";

function EnergyDashboard() {
    const navigate = useNavigate();

    const[SiteEnerySummary, setSiteEnerySummary] = useState([]);

    const [siteName, setSiteName] = useState('');

    const params = useParams();
    const id = params['id'];
    const siteid = params['site'];


    let energyName = '';
    if(id == 1) {
        energyName = 'Electric';
    } else if(id == 2) {
        energyName = 'Natrual Gas';
    } else if(id == 6) {
        energyName = 'LPG';
    } else if(id == 16) {
        energyName = 'Gas Oil';
    } else if(id == 15) {
        energyName = 'Burning Oil';
    } else {
        energyName = 'Energy';
    }

    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + siteid,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            });
        }
        loadSite();
        const loadEnergySection = async () => {
            axiosApi.get(
                '/pillar/2/site-energy-meter-entries/summary',
                {
                    params: {
                        site_id: siteid,
                        start_date: '2017-01-01',
                        end_date: '2024-02-31',
                        by_energy_type: 1
                    }
                }).then(function (response) {
                let energySummary = [];
                let summary = response['data']['data']['summary'];
                if (summary['by_energy_type'].length > 0) {
                    for (let i = 0; i < summary['by_energy_type'].length; i++) {

                        let entry =  summary['by_energy_type'][i];
                        let type = entry['energy_type'];
                        energySummary[type] = [];
                        energySummary[type]['amount'] = parseNumber(entry['total_amount'])
                        for(let j=0; j<entry['ghg_emissions'].length; j++) {
                            let emission = entry['ghg_emissions'][j];

                            if(emission['measurement'] == 'kgCO2') {
                                energySummary[type]['kgco2'] = parseNumber(emission['total_amount']);
                            } else if(emission['measurement'] == 'kgCO2e') {
                                energySummary[type]['kgco2e'] = parseNumber(emission['total_amount']);
                                let tonne = (parseNumber(emission['total_amount']) / 1000);
                                energySummary[type]['tco2e'] = tonne.toFixed(3);
                            } else if(emission['measurement'] == 'Kilowatt Hour (Gross CV)') {
                                energySummary[type]['kwh'] = parseNumber(emission['total_amount']);
                            }
                        }

                    }
                }
                setSiteEnerySummary(energySummary);

            });
        }
        loadEnergySection();


    }, [siteid]);

    return (
        <Grid>
            <EnegryNavbar/>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion">
                                <Link to={'/sitelanding/' + siteid} className="linkBack">&#11164; Back to Site Landing</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion padAlignRight">
                                <p>Start and End Dates:</p>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date(2016,8,1,0,0,0)} defaultShow={false} format={"dd-MM-yyyy"} name="startDate" id="startDate" />
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date()} defaultShow={false} format={"dd-MM-yyyy"} name="endDate" id="endDate" />
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Energy Summary-{ siteName }</strong></div>
                        <Grid className="display">

                            <Cell small={12} medium={6} large={6}>
                                <Grid className="display">
                                    <Cell small={12} medium={6} large={6}>
                                        <Link to={'/energy/'+siteid+'/1'}>
                                        <div className="quickSelectTab">
                                            <h4>Electricity</h4>
                                            <hr/>
                                            <table className="statsTable">
                                                <tr>
                                                    <td>Total Amount:</td>
                                                    <td>{ SiteEnerySummary['Electricity']? SiteEnerySummary['Electricity']['amount'] : 0 }</td>
                                                </tr>
                                                <tr>
                                                    <td>Total kgCO2e:</td>
                                                    <td>{ SiteEnerySummary['Electricity']? SiteEnerySummary['Electricity']['kgco2e'] : 0 }</td>
                                                </tr>
                                                <tr>
                                                    <td>Total tCO2e:</td>
                                                    <td>{ SiteEnerySummary['Electricity']? SiteEnerySummary['Electricity']['tco2e'] : 0 }</td>
                                                </tr>
                                                <tr>
                                                    <td>Total kWh:</td>
                                                    <td>{ SiteEnerySummary['Electricity']? SiteEnerySummary['Electricity']['kwh'] : 0 }</td>
                                                </tr>
                                            </table>
                                        </div>
                                        </Link>
                                    </Cell>
                                    <Cell small={12} medium={6} large={6}>
                                        <Link to={'/energy/'+siteid+'/2'}>
                                        <div className="quickSelectTab">
                                            <h4>Natural Gas</h4>
                                            <hr/>
                                            <table className="statsTable">
                                                <tr>
                                                    <td>Total Amount:</td>
                                                    <td>{ SiteEnerySummary['Natural Gas']? SiteEnerySummary['Natural Gas']['amount'] : 0 }</td>
                                                </tr>
                                                <tr>
                                                    <td>Total kgCO2e:</td>
                                                    <td>{ SiteEnerySummary['Natural Gas']? SiteEnerySummary['Natural Gas']['kgco2e'] : 0 }</td>
                                                </tr>
                                                <tr>
                                                    <td>Total tCO2e:</td>
                                                    <td>{ SiteEnerySummary['Natural Gas']? SiteEnerySummary['Natural Gas']['tco2e'] : 0 }</td>
                                                </tr>
                                                <tr>
                                                    <td>Total kWh:</td>
                                                    <td>{ SiteEnerySummary['Natural Gas']? SiteEnerySummary['Natural Gas']['kwh'] : 0 }</td>
                                                </tr>
                                            </table>
                                        </div>
                                        </Link>
                                    </Cell>
                                    <Cell small={12} medium={6} large={6}>
                                        <Link to={'/energy/'+siteid+'/6'}>
                                        <div className="quickSelectTab">
                                            <h4>LPG</h4>
                                            <hr/>
                                            <table className="statsTable">
                                                <tr>
                                                    <td>Total Amount:</td>
                                                    <td>{ SiteEnerySummary['LPG']? SiteEnerySummary['LPG']['amount'] : 0 }</td>
                                                </tr>
                                                <tr>
                                                    <td>Total kgCO2e:</td>
                                                    <td>{ SiteEnerySummary['LPG']? SiteEnerySummary['LPG']['kgco2e'] : 0 }</td>
                                                </tr>
                                                <tr>
                                                    <td>Total tCO2e:</td>
                                                    <td>{ SiteEnerySummary['LPG']? SiteEnerySummary['LPG']['tco2e'] : 0 }</td>
                                                </tr>
                                                <tr>
                                                    <td>Total kWh:</td>
                                                    <td>{ SiteEnerySummary['LPG']? SiteEnerySummary['LPG']['kwh'] : 0 }</td>
                                                </tr>
                                            </table>
                                        </div>
                                        </Link>
                                    </Cell>
                                    <Cell small={12} medium={6} large={6}>
                                        <Link to={'/energy/'+siteid+'/16'}>
                                        <div className="quickSelectTab">
                                            <h4>Gas Oil</h4>
                                            <hr/>
                                            <table className="statsTable">
                                                <tr>
                                                    <td>Total Amount:</td>
                                                    <td>{ SiteEnerySummary['Gas Oil']? SiteEnerySummary['Gas Oil']['amount'] : 0 }</td>
                                                </tr>
                                                <tr>
                                                    <td>Total kgCO2e:</td>
                                                    <td>{ SiteEnerySummary['Gas Oil']? SiteEnerySummary['Gas Oil']['kgco2e'] : 0 }</td>
                                                </tr>
                                                <tr>
                                                    <td>Total tCO2e:</td>
                                                    <td>{ SiteEnerySummary['Gas Oil']? SiteEnerySummary['Gas Oil']['tco2e'] : 0 }</td>
                                                </tr>
                                                <tr>
                                                    <td>Total kWh:</td>
                                                    <td>{ SiteEnerySummary['Gas Oil']? SiteEnerySummary['Gas Oil']['kwh'] : 0 }</td>
                                                </tr>
                                            </table>
                                        </div>
                                        </Link>
                                    </Cell>
                                    <Cell small={12} medium={6} large={6}>
                                        <Link to={'/energy/'+siteid+'/15'}>
                                            <div className="quickSelectTab">
                                                <h4>Burning Oil</h4>
                                                <hr/>
                                                <table className="statsTable">
                                                    <tr>
                                                        <td>Total Amount:</td>
                                                        <td>{ SiteEnerySummary['Burning Oil']? SiteEnerySummary['Burning Oil']['amount'] : 0 }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total kgCO2e:</td>
                                                        <td>{ SiteEnerySummary['Burning Oil']? SiteEnerySummary['Burning Oil']['kgco2e'] : 0 }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total tCO2e:</td>
                                                        <td>{ SiteEnerySummary['Burning Oil']? SiteEnerySummary['Burning Oil']['tco2e'] : 0 }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total kWh:</td>
                                                        <td>{ SiteEnerySummary['Burning Oil']? SiteEnerySummary['Burning Oil']['kwh'] : 0 }</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </Link>
                                    </Cell>
                                </Grid>
                            </Cell>

                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default EnergyDashboard;